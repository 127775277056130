import { connect } from "react-redux";
import React from "react";
import { endCurrentSession } from "../modules/session/session-actions";
import { getCurrentSessionId } from "../selectors";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";

class SessionOverviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.endSession = this.endSession.bind(this);
  }

  endSession(e) {
    e.preventDefault();
    this.props.endCurrentSession(this.props.machineId);
    this.props.history.push("/");
  }

  render() {
    let name, id;
    if (this.props.athlete) {
      name = this.props.athlete.name;
      id = this.props.athlete.id;
    }
    // console.log("CURR", this.props.currentSessionId);
    return (
      <div className="page-wrapper">
        <h1>Session In Progress</h1>
        <p></p>
        <p>Machine: {this.props.machineName}</p>
        <p>Athlete: {name}</p>

        <button onClick={this.endSession}>End Session</button>
        <button onClick={() => this.props.history.push(`/athlete-log/${id}`)}>
          See Results
        </button>

        <UserFeedbackDrawer />
      </div>
    );
  }
}

export { SessionOverviewPage };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state) => {
  const machineId = state.machineReducer.selectedMachineId;
  const currentSessionId = getCurrentSessionId(state, machineId);
  let machineName;
  if (machineId) {
    machineName = state.machineReducer.entities[machineId].name;
  }
  const trainerId = state.accountReducer.currentUserId;
  const session = state.sessionReducer.entities[currentSessionId];
  if (!session) {
    return {};
  }
  const userId = session.user_id;
  const athlete = state.athleteReducer.entities[userId];
  return { currentSessionId, athlete, trainerId, machineId, machineName };
};

export default connect(mapStateToProps, { endCurrentSession })(
  SessionOverviewPage
);

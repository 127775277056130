import React from "react";

interface ScoreBoxProps {
  label: string;
  children: React.ReactNode;
}

const ScoreBox = ({ label, children }: ScoreBoxProps): JSX.Element => {
  return (
    <div style={{ border: "thin solid black", textAlign: "center" }}>
      <h3 style={{ display: "inline-block" }}>{label}</h3>
      <span
        style={{
          margin: "10px",
          fontSize: "1.5em",
          fontWeight: 600,
          color: "#CC2401",
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default ScoreBox;

import React from "react";

import "./form-radio-select-grid-container.scss";

interface FormRadioSelectGridContainerProps {
  children: React.ReactNode;
}

const FormRadioSelectGridContainer = ({
  children,
}: FormRadioSelectGridContainerProps): JSX.Element => {
  return <div className="a-formRadioSelectGridContainer">{children}</div>;
};

export default FormRadioSelectGridContainer;

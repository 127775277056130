import React from "react";

import "./common-list-item.scss";

class CommonListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { entity, dataDisplayDefinitions } = this.props;
    const entityKeys = dataDisplayDefinitions.map((definition) => {
      return { key: definition.key, subKeys: definition.subKeys };
    });
    const entityStyles = dataDisplayDefinitions.map(
      (definition) => definition.style
    );
    const rowClass = entity.highlighted
      ? "data-row data-row--highlighted"
      : "data-row";
    return (
      <tr className={rowClass}>
        {entityKeys.map((keyRing, index) => {
          const key = keyRing.key;
          const subKeys = keyRing.subKeys ? keyRing.subKeys : [];
          let value;
          value = entity[key];
          subKeys.forEach((subKey) => {
            value = value[subKey];
          });
          return (
            <td
              key={`${entity.id}-${key}${subKeys.join()}`}
              className={`data-row__value`}
              style={entityStyles[index]}
            >
              {value}
            </td>
          );
        })}
      </tr>
    );
  }
}

export default CommonListItem;

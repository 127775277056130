import React from "react";
import CommonDisplayField from "../display-field";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class CommonDateTimeDisplayField extends React.Component {
  render() {
    const dateTime = new Date(this.props.dateTime);
    const day = days[dateTime.getDay()];
    const month = months[dateTime.getMonth()];
    const date = dateTime.getDate();
    const year = dateTime.getFullYear();
    let hour = dateTime.getHours();
    let ampm = "AM";
    if (hour > 12) {
      hour -= 12;
      ampm = "PM";
    }
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    let display = `${day} ${month}, ${date} ${year} ${hour}:${minutes} ${ampm}`;
    return <CommonDisplayField>{display}</CommonDisplayField>;
  }
}

export default CommonDateTimeDisplayField;

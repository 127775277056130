import React from "react";
import { Link } from "react-router-dom";

import LeftArrow from "/resources/svg/left-arrow--black.svg";
import RightArrow from "/resources/svg/right-arrow--black.svg";

import "./side-navigation.scss";

class SideNavigation extends React.Component {
  render() {
    return (
      <div className="side-navigation">
        <Link to={this.props.leftLink}>
          <div className="side-navigation__left">
            {this.props.leftLink ? (
              <React.Fragment>
                <div className="side-navigation__left__arrow">
                  <img src={LeftArrow} />
                </div>
                <div className="side-navigation__left__text">
                  {this.props.leftText}
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Link>
        <Link to={this.props.rightLink}>
          <div className="side-navigation__right">
            {this.props.rightLink ? (
              <React.Fragment>
                <div className="side-navigation__right__text">
                  {this.props.rightText}
                </div>
                <div className="side-navigation__right__arrow">
                  <img src={RightArrow} />
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Link>
      </div>
    );
  }
}

export default SideNavigation;

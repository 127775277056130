import React from "react";
import { connect } from "react-redux";
import { getExerciseDataTypes } from "../../../../../../selectors";
import SegmentRecordResultsGraph from "../../results-graph";
import {
  getRecordHRLineData,
  getRecordLineData,
  processJumpRecordSegments,
} from "../../graph_utilities";

class SegmentRecordCardioRecordGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterRate: 12,
      lines: {},
      xAxis: [],
    };
  }

  componentDidMount() {
    const segmentRecord = this.props.segmentRecord;

    const { jumpSamplesInRange, xAxis } = processJumpRecordSegments({
      segmentRecord,
      segType: null,
      filterRate: this.state.filterRate,
      segDataType: this.props.exerciseDataTypes.TARGETED_CARDIO,
    });

    const heartBPMSamples = segmentRecord.heartBPMSamples;
    const lines = {
      jumps: {
        data: getRecordLineData(jumpSamplesInRange, "validJumps"),
        color: "#6FF384",
        duration: 2000,
        label: "Jumps",
      },
      // 'jump percentage': {
      //   data: getRecordLineData(samplesInRange, 'jumpPercentage'),
      //   color: 'lightgreen',
      //   duration: 4000
      // },
      // 'jumpsGood': {
      //   data: getRecordLineData(samplesInRange, 'validJumpsGood'),
      //   color: '#796FF3',
      //   duration: 3000,
      //   label: 'Jumps Good',
      //},
      heartBPM: {
        data: getRecordHRLineData(heartBPMSamples),
        color: "#CC2401",
        duration: 4000,
        label: "Heart Rate",
      },
    };

    this.setState({
      lines,
      xAxis,
    });
  }

  render() {
    const lines = this.state.lines;
    const xAxis = this.state.xAxis;
    let page = <div>Loading Graph...</div>;
    if (xAxis.length > 0) {
      page = (
        <div>
          <SegmentRecordResultsGraph
            lines={lines}
            chartLabel="Cardio Record"
            xAxis={xAxis}
            baselineSegmentRecord={{}}
            scale="mseconds"
            history={this.props.history}
          />
        </div>
      );
    }
    return page;
  }
}

const mapStateToProps = (state, props) => {
  const segmentRecord = state.segmentRecordReducer.entities[props.sessionId];
  const exerciseDataTypes = getExerciseDataTypes();
  return {
    segmentRecord,
    exerciseDataTypes,
  };
};

export default connect(mapStateToProps, {})(SegmentRecordCardioRecordGraph);

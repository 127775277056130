import React, { useContext } from "react";
import { FormContext } from "../form-context";

/* eslint-disable react/display-name */
const standardInputWrapper =
  (Component) =>
  ({ ...props }) => {
    const { setValue, values } = useContext(FormContext);
    const value = values[props.name] ?? "";
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.name, e.target.value);
    };

    return <Component onChange={onChange} value={value} {...props} />;
  };

export default standardInputWrapper;

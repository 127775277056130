import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import DownArrow from "../../../../svg/down-arrow--red.svg";
import "./user-dropdown.scss";

class UserDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let menuClassModifier = "closed";
    if (this.state.open) {
      menuClassModifier = "open";
    }
    return (
      <div className="user-dropdown">
        <div className="user-dropdown__username" onClick={this.toggleMenu}>
          <div className="user-dropdown__username__text">{this.props.name}</div>
          <img className="user-dropdown__username__img" src={DownArrow}></img>
        </div>
        <div
          className={`user-dropdown__menu user-dropdown__menu--${menuClassModifier}`}
        >
          <Link to={`/my-profile`}>Edit Profile</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let trainerId = state.accountReducer.currentUserId;
  let name = state.accountReducer.currentUserName;
  return {
    trainerId,
    name,
  };
};

export default connect(mapStateToProps, {})(UserDropDown);

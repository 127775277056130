import { insertOrUpdateMultiple } from "../../easy-reducer.js";

export const athleteActionTypes = {
  getAll: "GET_ALL_ATHLETES",
  clearAthletes: "CLEAR_ATHLETES",
  athleteRegistrationReqSent: "ATHLETE_REGISTRATION_REQUEST_SENT",
  clearFormSuccess: "CLEAR_FORM_SUCCESS",
  athleteRegistered: "ATHLETE_REGISTERED",
  registrationACK: "REGISTRATION_ACK",
  clearGeneratedUserCode: "CLEAR_GENERATED_USER_CODE",
  userCodeReceived: "USER_CODE_RECEIVED",
  editAthlete: "EDIT_ATHLETE",
  registerNewAthlete: "REGISTER_NEW_ATHLETE",
  registerNewAthletesInBulk: "REGISTER_NEW_ATHLETES_IN_BULK",
  getAthleteById: "GET_ATHLETE_BY_ID",
  getNewAthleteById: "GET_NEW_ATHLETE_BY_ID",
  requestUserCode: "REQUEST_USER_CODE",
};

export const stateShape = {
  entities: {},
  formSubmissionState: {},
  generatedUserCode: null,
};

const athleteReducer = (state = stateShape, action) => {
  switch (action.type) {
    case "NORM_DATA_RECEIVED": {
      let newState = null;

      const { athletes } = action;

      // check for new athletes
      if (athletes) {
        newState = insertOrUpdateMultiple(state, athletes);
      }
      if (newState === null) return state;
      return newState;
    }

    case athleteActionTypes.clear: {
      const newState = stateShape;
      return newState;
    }

    case athleteActionTypes.athleteRegistrationReqSent: {
      let newState = { ...state };
      // console.log(state.formSubmissionState);
      newState.formSubmissionState = { ...state.formSubmissionState };
      newState.formSubmissionState[action.id] = "waiting";
      // console.log(newState.formSubmissionState);
      return newState;
    }

    case athleteActionTypes.clearFormSuccess: {
      let newState = { ...state };
      newState.formSubmissionState = { ...state.formSubmissionState };
      delete newState.formSubmissionState[action.id];
      return newState;
    }

    case athleteActionTypes.athleteRegistered: {
      let newState = { ...state };
      newState.formSubmissionState = { ...state.formSubmissionState };
      if (action.errors) {
        newState.formSubmissionState[action.id] = {
          errors: action.errors,
          submittedData: action.athleteData,
        };
      } else {
        newState.formSubmissionState[action.id] = "success";
      }
      return newState;
    }

    case athleteActionTypes.registrationACK: {
      let newState = { ...state };
      newState.formSubmissionState = { ...state.formSubmissionState };
      newState.formSubmissionState[action.id] = null;
      return newState;
    }

    case athleteActionTypes.clearGeneratedUserCode: {
      let newState = { ...state };
      newState.generatedUserCode = null;
      return newState;
    }

    case athleteActionTypes.userCodeReceived: {
      let newState = { ...state };
      newState.generatedUserCode = action.userCode;
      return newState;
    }

    default:
      return state;
  }
};
export default athleteReducer;

import React from "react";

import FormRadioSelectGridContainer from "../../../form/radio-select/grid-container/form-radio-select-grid-container";
import SCAT5InputRadio from "../../input/radio/scat5-input-radio";
import ScoreBox from "../../../form/score-box/score-box";

import { useFormContextValue } from "../../form-context";

const SACOrientation = (): JSX.Element => {
  const scores = [
    useFormContextValue("knowsMonth") == "true" ? 1 : 0,
    useFormContextValue("knowsDate") == "true" ? 1 : 0,
    useFormContextValue("knowsDayOfWeek") == "true" ? 1 : 0,
    useFormContextValue("knowsYear") == "true" ? 1 : 0,
    useFormContextValue("knowsHourOfDay") == "true" ? 1 : 0,
  ];
  const sum = scores.reduce((a, b) => a + b);

  return (
    <React.Fragment>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What month is it?"
          name="knowsMonth"
          options={{
            0: false,
            1: true,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What is the date today?"
          name="knowsDate"
          options={{
            0: false,
            1: true,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What is the day of the week?"
          name="knowsDayOfWeek"
          options={{
            0: false,
            1: true,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What year is it?"
          name="knowsYear"
          options={{
            0: false,
            1: true,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What time is it right now?"
          name="knowsHourOfDay"
          options={{
            0: false,
            1: true,
          }}
        />
      </FormRadioSelectGridContainer>
      <ScoreBox label="Orientation Score">{sum}</ScoreBox>
    </React.Fragment>
  );
};

export default SACOrientation;

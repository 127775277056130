import React from "react";

import "./form-radio-select.scss";
const FormRadioSelect = (props) => {
  interface PropTypes {
    label: string;
    name: string;
    value: any;
    options: object;
    modifiers?: Array<string>;
    onChange: React.ChangeEventHandler;
  }
  const { label, name, value, options, modifiers, onChange }: PropTypes = props;

  const inputArray = Object.entries(options).map(
    ([optionLabel, optionValue]: [string, any]) => {
      return (
        <li key={optionLabel} className="o-radioSelect__item">
          <label className="o-radioSelect__label ">
            <span className="a-radioInput o-radioSelect__input">
              <input
                type="radio"
                value={optionValue}
                checked={optionValue.toString() === value ? true : false}
                onChange={onChange}
                name={name}
                className={"a-radioInput__input"}
              />
              <span className="a-radioInput__checkbox"></span>
            </span>
            {optionLabel}
          </label>
        </li>
      );
    }
  );
  return (
    <React.Fragment>
      <h3
        className={`o-radioSelect__title ${
          modifiers ? `-${modifiers.join(" -")}` : null
        }`}
      >
        {label}
      </h3>
      <ul
        className={`o-radioSelect ${
          modifiers ? `-${modifiers.join(" -")}` : null
        }`}
      >
        {inputArray}
      </ul>
    </React.Fragment>
  );
};

export default FormRadioSelect;

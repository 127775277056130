import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  createSegmentRecord,
  clearFormSuccess,
} from "../modules/segment-record/segment-record-actions";

import AppBar from "../modules/common/app-bar/app-bar";
import CommonButton from "../modules/common/button/common-button";
import OnFieldSCAT5Assesment from "../modules/scat5/assesments/on-field-scat5-assesment";
import OfficeSCAT5Assessment from "../modules/scat5/assesments/office-scat5-assesment";
import SideNavigation from "../modules/side-navigation/side-navigation";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";

const SCAT5Page = (props: any) => {
  const [form, setForm] = useState("unset");

  const formId = `SCAT5-${props.athleteId}`;
  const formState = props.formSubmissionState[formId];
  const { clearFormSuccess } = props;

  useEffect(() => {
    clearFormSuccess(formId);
  }, [clearFormSuccess, formId]);

  useEffect(() => {
    if (formState) {
      setForm("unset");
    }
  }, [formState]);

  useEffect(() => {
    // console.log("hi");
    // console.log(form);
    if (form != "unset") {
      // console.log("hello");
      clearFormSuccess(formId);
    }
  }, [form, clearFormSuccess, formId]);

  const onSubmit = (segments: Record<string, unknown>) => {
    // console.log("submitting", segments);
    const segmentRecord = { appletName: "SCAT5", segments };
    props.createSegmentRecord(
      "SCAT5",
      props.athleteId,
      props.trainerId,
      segmentRecord
    );
  };

  const display = () => {
    switch (form) {
      case "field":
        return (
          <OnFieldSCAT5Assesment
            onSubmit={onSubmit}
            athlete={props.athlete}
            trainer={props.trainer}
          />
        );
      case "office":
        return (
          <OfficeSCAT5Assessment
            onSubmit={onSubmit}
            athlete={props.athlete}
            trainer={props.trainer}
          />
        );
      case "unset":
        return (
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <CommonButton onClick={() => setForm("field")}>Field</CommonButton>
            <CommonButton onClick={() => setForm("office")}>
              Office
            </CommonButton>
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      <AppBar pageName="SCAT5" />
      {formState ? (
        <div
          style={{
            width: "fit-content",
            margin: "10px auto",
            padding: "5px",
            textAlign: "center",
            border: "solid #CC2401",
            borderRadius: "4px",
          }}
        >
          <div style={{}}>{formState.message}</div>
        </div>
      ) : null}
      {display()}
      <SideNavigation
        leftLink={`/athlete-log/${props.athleteId}`}
        leftText="Athlete Log"
      />
      <UserFeedbackDrawer />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, props: any) => {
  const athleteId = props.match.params.athleteId;
  const athlete = state.athleteReducer.entities[athleteId];
  const trainerId = state.accountReducer.currentUserId;
  const trainer = state.accountReducer.currentUserName;
  const formSubmissionState = state.segmentRecordReducer.formSubmissionState;
  return { athleteId, athlete, trainerId, trainer, formSubmissionState };
};

export default connect(mapStateToProps, {
  createSegmentRecord,
  clearFormSuccess,
})(SCAT5Page);

import React from "react";

import "./common-form-input.scss";

class CommonFormInput extends React.Component {
  render() {
    let inputClassName = this.props.error
      ? "common-form__input common-form__input--error"
      : "common-form__input";
    return (
      <React.Fragment>
        <label className="common-form__label" htmlFor={this.props.id}>
          {this.props.label}
        </label>
        <input
          type={this.props.type}
          name={this.props.name}
          id={this.props.id}
          className={inputClassName}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        {this.props.error ? (
          <p className="common-form__validation-error">{this.props.error[0]}</p>
        ) : null}
      </React.Fragment>
    );
  }
}

export default CommonFormInput;

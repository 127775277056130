import React from "react";

import "./common-list-header.scss";

class CommonListHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dataDisplayDefinitions } = this.props;
    let keyStr;
    if (dataDisplayDefinitions) {
      keyStr = dataDisplayDefinitions.map((definition) => (
        <th
          className="data-header"
          key={`${definition.key}${
            definition.subKeys ? definition.subKeys.join("") : null
          }-header`}
        >
          {definition.display}
        </th>
      ));
    }
    return (
      <thead>
        <tr>{keyStr}</tr>
      </thead>
    );
  }
}

export default CommonListHeader;

// eventually we may wrap this into a class, but for now we're keeping it functional
// so it's easier to change
import _ from "lodash";

// converts a single object, or integer mapped object, to an array
export function arrayWrap(item) {
  let retItem = item;
  if (!Array.isArray(item)) {
    // if its not an array, see if its an object mapped as an array
    const keys = Object.keys(item);
    let isMappedObject = true;
    let keysUUIDs = true;

    for (let i = 0; i < keys.length; i++) {
      // we could probably do better, but this is a cheap way
      // to see if the thing coming in has already been normalized
      if (keys[i] && keys[i].length !== 36) keysUUIDs = false;
      if (isNaN(keys[i]) && !keysUUIDs) {
        isMappedObject = false;
        break;
      }
    }

    // if its a mapped object convert it to an array
    if (isMappedObject) {
      retItem = keys.map((id) => item[id]);
    } else {
      // otherwise just wrap it in an array
      retItem = [item];
    }
  }
  return retItem;
}

// function for manually inserting into an array
export function insertOrUpdateInto(targetObject, item, findFunc = null) {
  const newObj = { ...targetObject };

  // see if it exists
  let objIndex = null;
  if (_.isObject(findFunc)) {
    objIndex = _.findIndex(targetObject, item, findFunc);
  } else if (_.isFunction(findFunc)) {
    objIndex = findFunc(targetObject, item);
  } else if (findFunc === null) {
    objIndex = item.id;
  } else {
    objIndex = findFunc;
  }

  // stash if new item
  if (objIndex === null && !newObj[objIndex]) {
    newObj[objIndex] = item;
  } else {
    // update existing item
    // allow for partial update
    newObj[objIndex] = { ...newObj[objIndex], ...item };
  }

  return newObj;
}

export function insertOrUpdate(state, item) {
  const newState = { ...state };
  newState.entities = { ...newState.entities };
  // stash if new item
  if (!newState.entities[item.id]) {
    newState.order = [newState.entities];
    newState.order.push(item.id);
    newState.entities[item.id] = item;
  } else {
    // update existing item
    // allow for partial update
    newState.entities[item.id] = Object.assign(
      {},
      newState.entities[item.id],
      item
    );
  }

  return newState;
}

export function insertOrUpdateMultiple(state, items) {
  // make sure theres something to add
  if (!items) return state;

  // create a new
  let newState = { ...state };
  Object.keys(items).map((id) => {
    const card = items[id];

    // stash if new card
    newState = insertOrUpdate(newState, card);
  });

  return newState;
}

// normalizr helpers
export function denormalize(normalizedData, list = null) {
  if (list === undefined) return [];

  // consider removing this
  if (list == null) {
    list = normalizedData.order;
  }

  const retList = list.map((id) => normalizedData.entities[id]);

  return retList;
}

import { insertOrUpdateMultiple } from "../../easy-reducer.js";

export const machineActionTypes = {
  getMachineSessions: "GET_MACHINE_SESSIONS",
  selectMachine: "SELECT_MACHINE",
  setCurrentSession: "SET_CURRENT_SESSION",
};

export const stateShape = { entities: {}, selectedMachineId: null };

const machineReducer = (state = stateShape, action) => {
  switch (action.type) {
    case "NORM_DATA_RECEIVED": {
      let newState = null;

      const { machines } = action;

      // check for new machines
      if (machines) {
        newState = insertOrUpdateMultiple(state, machines);
      }
      if (newState === null) return state;
      return newState;
    }

    case "CLEAR_MACHINES": {
      const newState = stateShape;
      return newState;
    }

    case machineActionTypes.selectMachine: {
      let newState = { ...state };
      newState.selectedMachineId = action.machineId;
      return newState;
    }

    case machineActionTypes.setCurrentSession: {
      let newState = { ...state };
      newState.entities = { ...state.entities };
      newState.entities[action.machineId] = {
        ...state.entities[action.machineId],
        current_session_id: action.sessionId,
      };
      return newState;
    }

    default:
      return state;
  }
};
export default machineReducer;

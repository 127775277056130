import React, { useEffect } from "react";

import CSVFileLoader from "../../../file-loader/csv/csv-file-loader";

interface AthleteBulkImportCSVProps {
  onData: (data: Array<Record<string, string>>) => void;
}

const requiredFields = ["name", "email"];
const optionalFields = ["dob", "userCode", "weight", "height"];

const AthleteBulkImportCSV = ({
  onData,
}: AthleteBulkImportCSVProps): JSX.Element => {
  const [fieldMap, setFieldMap] = React.useState({});
  const [csvData, setCSVData] = React.useState([]);
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (!error && fieldMap !== {} && csvData !== []) {
      const athletes = csvData.map((row) => {
        const athlete = {};
        requiredFields.forEach((field) => {
          athlete[field] = row[fieldMap[field]];
        });
        optionalFields.forEach((field) => {
          if (row[fieldMap[field]]) {
            athlete[field] = row[fieldMap[field]];
          }
        });
        return athlete;
      });
      onData(athletes);
    } else {
      if (error) {
        onData([]);
      }
    }
  }, [fieldMap, error, csvData, onData]);

  useEffect(() => {
    error ? console.error("error", error) : null;
  }, [error]);

  //console.log("fieldMap", fieldMap);
  //console.log("athleteData", athleteData);

  const checkFields = (headers: Array<string>): void => {
    const lowercaseHeaders = headers.map((header) => header.toLowerCase());
    const fields = requiredFields.concat(optionalFields);
    const missingRequiredFields = requiredFields.filter(
      (field) => !lowercaseHeaders.includes(field.toLowerCase())
    );
    const missingFields = fields.filter(
      (field) => !lowercaseHeaders.includes(field.toLowerCase())
    );
    if (missingFields.length > 0) {
      // console.log("missingFields", missingFields);
    }
    if (missingRequiredFields.length > 0) {
      setError(`Missing required fields: ${missingRequiredFields.join(", ")}`);
      return;
    }
    const newFieldMap = {};
    for (const field of requiredFields) {
      const index = lowercaseHeaders.indexOf(field.toLowerCase());
      if (index >= 0) {
        newFieldMap[field] = headers[index];
      }
    }
    for (const field of optionalFields) {
      const index = lowercaseHeaders.indexOf(field.toLowerCase());
      if (index >= 0) {
        newFieldMap[field] = headers[index];
      }
    }
    setError("");
    setFieldMap(newFieldMap);
  };

  const onParse = (data) => {
    //console.log("onData", data);
    checkFields(data.headers);
    setCSVData(data.rows);
  };

  return (
    <div className="o-athleteCSVImport">
      <p>Import multiple athletes via CSV</p>
      <CSVFileLoader onParse={onParse} />
      {error ? (
        <div className="o-athleteCSVImport__message -error">{error}</div>
      ) : null}
    </div>
  );
};

export default AthleteBulkImportCSV;

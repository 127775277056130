import { insertOrUpdateMultiple } from "../../easy-reducer.js";

export const segmentRecordActionTypes = {
  getHistoryById: "GET_HISTORY_BY_ID",
  createSegmentRecord: "CREATE_SEGMENT_RECORD",
  getSegmentRecordHeaderById: "GET_SEGMENT_RECORD_HEADER_BY_ID",
  getSegmentRecordHeaderByIdList: "GET_SEGMENT_RECORD_HEADER_BY_ID_LIST",
  toggleSegmentRecordSelection: "TOGGLE_SEGMENT_RECORD_SELECTION",
  clearSegmentRecordSelection: "CLEAR_SEGMENT_RECORD_SELECTION",
  getSegmentRecordById: "GET_SEGMENT_RECORD_BY_ID",
  getSegmentRecordsByIdList: "GET_SEGMENT_RECORD_BY_ID_LIST",
  receiveAthlete: "RECEIVE_ATHLETE",
  getSessionsBySegmentRecordIdList: "GET_SESSIONS_BY_SEGMENT_RECORD_ID_LIST",
  segmentRecordRegistrationSent: "SEGMENT_RECORD_REGISTRATION_SENT",
  clearFormSuccess: "CLEAR_FORM_SUCCESS",
  segmentRecordRegistered: "SEGMENT_RECORD_REGISTERED",
};

export const stateShape = {
  entities: {},
  baselineId: null,
  formSubmissionState: {},
};

const SegmentRecordReducer = (state = stateShape, action) => {
  switch (action.type) {
    case "NORM_DATA_RECEIVED": {
      let newState = null;

      const { segmentRecord } = action;

      // check for new segmentRecords
      if (segmentRecord) {
        newState = insertOrUpdateMultiple(state, segmentRecord);
      }
      if (newState === null) return state;
      return newState;
    }

    case "CLEAR_SEGMENT_RECORDS": {
      const newState = stateShape;
      return newState;
    }

    case segmentRecordActionTypes.segmentRecordRegistrationSent: {
      let newState = { ...state };
      // console.log(state.formSubmissionState);
      newState.formSubmissionState = { ...state.formSubmissionState };
      newState.formSubmissionState[action.id] = { message: "Sending data" };
      // console.log(newState.formSubmissionState);
      return newState;
    }

    case segmentRecordActionTypes.clearFormSuccess: {
      let newState = { ...state };
      newState.formSubmissionState = { ...state.formSubmissionState };
      delete newState.formSubmissionState[action.id];
      return newState;
    }

    case segmentRecordActionTypes.segmentRecordRegistered: {
      let newState = { ...state };
      newState.formSubmissionState = { ...state.formSubmissionState };
      if (action.errors) {
        newState.formSubmissionState[action.id] = {
          message: "Error submitting data",
          errors: action.errors,
        };
      } else {
        newState.formSubmissionState[action.id] = {
          message: "Data submitted!",
        };
      }
      return newState;
    }

    case segmentRecordActionTypes.toggleSegmentRecordSelection: {
      // console.log("lets toggle ", action.segmentRecordId);
      if (!state.entities[action.segmentRecordId]) {
        console.warn(action.segmentRecordId, "Not in segmentRecord state");
        return state;
      }
      const newState = { ...state };
      newState.entities = { ...state.entities };
      //If toggled segmentRecord is the current baseline, clear it
      if (newState.baselineId == action.segmentRecordId) {
        newState.baselineId = null;
      } else {
        //If toggled segmentRecord is currently selected, clear it
        if (newState.entities[action.segmentRecordId].selected) {
          delete newState.entities[action.segmentRecordId].selected;
        } else {
          //If no baseline, then toggled segmentRecord will be the baseline
          if (!newState.baselineId) {
            newState.baselineId = action.segmentRecordId;
            // console.log("Set baseline: ", newState.baselineId);
          } else {
            //otherwise just select it
            newState.entities[action.segmentRecordId].selected = true;
          }
        }
      }
      return newState;
    }

    case segmentRecordActionTypes.clearSegmentRecordSelection: {
      const newState = { ...state };
      newState.entities = { ...state.entities };
      Object.values(newState.entities).forEach(
        (segmentRecord) => delete segmentRecord.selected
      );
      newState.baselineId = null;
      return newState;
    }

    default:
      return state;
  }
};
export default SegmentRecordReducer;

import React from "react";

import "./common-styled-dropdown.scss";

class CommonStyledDropdown extends React.Component {
  render() {
    const entities = this.props.entities;
    let options = Object.keys(entities).map((entityId) => {
      return (
        <option key={entityId} value={entities[entityId].id || entityId}>
          {entities[entityId].name}
        </option>
      );
    });
    return (
      <div className="common-styled-dropdown">
        <label
          className="common-styled-dropdown__label"
          htmlFor="common-styled-dropdown__dropdown"
        >
          {this.props.label}
        </label>
        <div className="common-styled-dropdown__dropdown__container">
          <div className="common-styled-dropdown__dropdown">
            <select
              id="common-styled-dropdown__dropdown"
              defaultValue={this.props.defaultValue}
              onChange={(e) => {
                this.props.onChange(e.target.value);
              }}
            >
              {options}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default CommonStyledDropdown;

import React from "react";
import ReactDOM from "react-dom";
import store from "./store.js";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import { getAllAthletesForTrainer } from "./modules/athlete/athlete-actions";
import { ErrorBoundaryComponent } from "./modules/error-boundary/error-boundary";

// redux
import { connect } from "react-redux";

//pages
import RosterPage from "./pages/roster-page";
import LoginPage from "./pages/login-page";
import LogoutForm from "./modules/account/logout/account-logout";
import SegmentRecordDisplayPage from "./pages/segment-record-page";
import CurrentSessionPage from "./pages/current-session-page";
import SegmentRecordComparePage from "./pages/compare-page";
import AthleteLogPage from "./pages/athlete-log-page.js";
import AthleteProfilePage from "./pages/athlete-profile-page";
import AddAthletePage from "./pages/add-athlete-page.js";
import TrainerProfilePage from "./pages/trainer-profile-page";
import MarsdenExercisePage from "./modules/marsden-ball-exercise/marsden-exercise-page";

import SCAT5Page from "./pages/scat5-page";

//actions
import { checkLogin } from "./modules/account/account-actions";

import "./app.scss";

class DigijumpRosterApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.checkLogin();

    if (this.props.trainerId) {
      this.props.getAllAthletesForTrainer(this.props.trainerId);
    }
  }

  render() {
    if (!this.props.signedIn) {
      return (
        <div className="app-wrapper">
          <HashRouter>
            <ErrorBoundaryComponent>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/" component={LoginPage} />
              </Switch>
            </ErrorBoundaryComponent>
          </HashRouter>
        </div>
      );
    }

    return (
      <div className="app-wrapper">
        <HashRouter>
          <ErrorBoundaryComponent>
            <Switch>
              <Route path="/logout" component={LogoutForm} />
              <Route
                path="/athlete-log/:athleteId"
                component={AthleteLogPage}
              />
              <Route
                path="/athlete-profile/:athleteId"
                component={AthleteProfilePage}
              />
              <Route
                path="/add-athlete/:machineId"
                component={AddAthletePage}
              />
              <Route path="/my-profile" component={TrainerProfilePage} />
              <Route path="/current-session" component={CurrentSessionPage} />
              <Route
                path="/segment-record/:segmentRecordId"
                component={SegmentRecordDisplayPage}
              />
              <Route path="/compare" component={SegmentRecordComparePage} />
              <Route path="/scat5/:athleteId" component={SCAT5Page} />

              <Route path="/marsden" component={MarsdenExercisePage} />

              <Route path="/" component={RosterPage} />
            </Switch>
          </ErrorBoundaryComponent>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("STATE", state);
  let signedIn = state.accountReducer.signedIn;
  let trainerId = state.accountReducer.currentUserId;
  return { signedIn, trainerId };
};

const DigijumpRosterAppComponent = connect(mapStateToProps, {
  checkLogin,
  getAllAthletesForTrainer,
})(DigijumpRosterApp);

if (document.getElementById("root")) {
  ReactDOM.render(
    <Provider store={store}>
      <DigijumpRosterAppComponent />
    </Provider>,
    document.getElementById("root")
  );
}

import React from "react";
import SegmentRecordResultsGraph from "../results-graph";

class SegmentRecordResistanceResultsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sortSegmentRecordsByDate(segmentRecords) {
    // console.log("%cSorting", "background:orange;");
    const sortedSegmentRecords = segmentRecords.slice().sort((b, a) => {
      if (a.segmentRecord && b.segmentRecord) {
        return (
          new Date(b.segmentRecord.segmentRecordStartTime).getTime() -
          new Date(a.segmentRecord.segmentRecordStartTime).getTime()
        );
      }
      return 0;
    });
    return sortedSegmentRecords;
  }

  getLineDatum(comparisonSegmentRecord, selectedStat) {
    let datum = {
      x: new Date(
        comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
      ).toString(),
    };
    datum["y"] = parseFloat(comparisonSegmentRecord.results[selectedStat]);
    return datum;
  }

  getLineData(segmentRecords, selectedStat) {
    return segmentRecords.map((comparisonSegmentRecord) =>
      this.getLineDatum(comparisonSegmentRecord, selectedStat)
    );
  }

  render() {
    const allSegmentRecords = [
      ...this.props.comparisonSegmentRecords,
      {
        id: this.props.baselineSegmentRecord.id,
        segmentRecord: this.props.baselineSegmentRecord,
        results: this.props.baselineResultData,
      },
    ];
    const resistanceSegmentRecords = this.sortSegmentRecordsByDate(
      allSegmentRecords.filter(
        (segmentRecord) => segmentRecord.results.resultTypes.resistance
      )
    );
    const lines = {
      elapsedResistanceTime: {
        data: this.getLineData(
          resistanceSegmentRecords,
          "elapsedResistanceTime"
        ),
        color: "#796FF3",
        duration: 2000,
        label: "Elapsed Resistance Time",
      },
      exercises: {
        data: this.getLineData(resistanceSegmentRecords, "exercises"),
        color: "#F3BE6F",
        duration: 1000,
        label: "Exercises",
      },
      pounds: {
        data: this.getLineData(resistanceSegmentRecords, "pounds"),
        color: "lightblue",
        duration: 3000,
        label: "Pounds",
      },
      totalReps: {
        data: this.getLineData(resistanceSegmentRecords, "totalReps"),
        color: "#6FF384",
        duration: 4000,
        label: "Total Reps",
      },
      averageResistanceHR: {
        data: this.getLineData(resistanceSegmentRecords, "averageResistanceHR"),
        color: "#CC2401",
        duration: 4000,
        label: "Average Heart Rate",
      },
    };
    const xAxis = resistanceSegmentRecords.map((comparisonSegmentRecord) => {
      return {
        id: comparisonSegmentRecord.segmentRecord.id,
        date: new Date(
          comparisonSegmentRecord.segmentRecord.segmentRecordStartTime
        ).toString(),
      };
    });
    return (
      <SegmentRecordResultsGraph
        lines={lines}
        chartLabel="Resistance Results"
        linkPage="segment-record"
        xAxis={xAxis}
        history={this.props.history}
        baselineSegmentRecord={this.props.baselineSegmentRecord}
        baselineResultData={this.props.baselineResultData}
        multipleAthletes={this.props.multipleAthletes}
        comparisonSegmentRecords={this.props.comparisonSegmentRecords}
      />
    );
  }
}

export default SegmentRecordResistanceResultsGraph;

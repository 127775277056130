export const rand = (max: number, min = 0): number =>
  min + Math.random() * (max - min);
export const randInt = (max, min = 0) =>
  Math.floor(min + Math.random() * (max - min));
export const randChoice = (arr) => arr[randInt(arr.length)];

export const map = (value, sMin, sMax, dMin, dMax) => {
  return dMin + ((value - sMin) / (sMax - sMin)) * (dMax - dMin);
};

export const range = (n, m = 0) =>
  Array(n)
    .fill(m)
    .map((i, j) => i + j);

// https://github.com/bit101/CodingMath
export const bez = (p0, p1, p2, t) => {
  const x = Math.pow(1 - t, 2) * p0.x + (1 - t) * 2 * t * p1.x + t * t * p2.x;
  const y = Math.pow(1 - t, 2) * p0.y + (1 - t) * 2 * t * p1.y + t * t * p2.y;
  return [x, y];
};

export const rad = (deg) => (deg / 180) * Math.PI;

//https://newbedev.com/seeding-the-random-number-generator-in-javascript
function xmur3(str) {
  for(var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
      h = Math.imul(h ^ str.charCodeAt(i), 3432918353),
      h = h << 13 | h >>> 19;
  return function() {
      h = Math.imul(h ^ h >>> 16, 2246822507);
      h = Math.imul(h ^ h >>> 13, 3266489909);
      return (h ^= h >>> 16) >>> 0;
  }
}

//Tommy Ettinger (tommy.ettinger@gmail.com) 2017 Public Domain
function mulberry32(a) {
  return function() {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  }
}

export const seededRandomNumberList = (seed, length, min = 0, max = 100) => {
  const getSeed = xmur3(seed);
  const random = mulberry32(getSeed());
  return range(length).map(() => Math.floor(random() * (max - min) + min));
}
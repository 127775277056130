import React from "react";

import FormRows from "../../form/rows/form-rows";
import SCAT5InputCommon from "../input/common/scat5-input-common";
import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import ScoreBox from "../../form/score-box/score-box";
import { useFormContextValue } from "../form-context";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";

interface GlasgowComaScaleFormProps {
  instanceId: number;
}

const GlasgowComaScaleForm = ({
  instanceId,
}: GlasgowComaScaleFormProps): JSX.Element => {
  const eyeScore = Number(useFormContextValue("bestEyeResponse"));
  const verbalScore = Number(useFormContextValue("bestVerbalResponse"));
  const motorScore = Number(useFormContextValue("bestMotorResponse"));
  const sum =
    eyeScore > 0 && verbalScore > 0 && motorScore > 0
      ? eyeScore + verbalScore + motorScore
      : "N/A";

  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
        STEP 4: EXAMINATION GLASGOW COMA SCALE (GCS)<sup>3</sup>
      </h2>
      <h3
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          color: "#CC2401",
        }}
      >
        ASSESSMENT {instanceId}
      </h3>
      <div style={{ margin: "0 auto", width: "500px" }}>
        <FormRows>
          <SCAT5InputCommon
            label="Time of Assessment"
            id="glasgow-time"
            name="glasgowTime"
            type="time"
          />
          <SCAT5InputCommon
            label="Date of Assessment"
            id="glasgow-date"
            name="glasgowDate"
            type="date"
          />
        </FormRows>
        <h3>
          <b></b>
        </h3>
        <SCAT5InputRadio
          label="Best eye response (E)"
          name="bestEyeResponse"
          options={{
            "1 No eye opening": 1,
            "2 Eye opening in response to pain": 2,
            "3 Eye opening to speech": 3,
            "4 Eyes opening spontaneously": 4,
          }}
        />
        <SCAT5InputRadio
          label="Best verbal response (V)"
          name="bestVerbalResponse"
          options={{
            "1 No verbal response": 1,
            "2 Incomprehensible sounds": 2,
            "3 Inappropriate words": 3,
            "4 Confused": 4,
            "5 Oriented": 5,
          }}
        />
        <SCAT5InputRadio
          label="Best motor response (M)"
          name="bestMotorResponse"
          options={{
            "1 No motor response": 1,
            "2 Extension to pain": 2,
            "3 Abnormal flexion to pain": 3,
            "4 Flexion / Withdrawal to pain": 4,
            "5 Localizes to pain": 5,
            "6 Obeys commands": 6,
          }}
        />
        <ScoreBox label="Glasgow Coma score (E + V + M):">{sum}</ScoreBox>
      </div>
    </React.Fragment>
  );
};

export default GlasgowComaScaleForm;

import React from "react";

import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import FormRadioSelectGridContainer from "../../form/radio-select/grid-container/form-radio-select-grid-container";

const MemoryAssesmentForm = (): JSX.Element => {
  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Step 3: MEMORY ASSESSMENT MADDOCK QUESTIONS<sup>2</sup>
      </h2>
      <p>
        <i>
          “I am going to ask you a few questions, please listen carefully and
          give your best effort. First, tell me what happened?”
        </i>
      </p>
      <p>
        <b style={{ color: "#CC2401" }}>
          Mark Y for correct answer / N for incorrect
        </b>
      </p>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What venue are we at today?"
          name="motionless"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Which half is it now?"
          name="balanceGaitIncoordination"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Who scored last in this match?"
          name="disorientation"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="What team did you play last week / game?"
          name="vacantLook"
          options={{
            Y: true,
            N: false,
          }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Did your team win the last game?"
          name="facialInjury"
          options={{
            Y: true,
            N: false,
          }}
        />
      </FormRadioSelectGridContainer>
      <p>
        <b>Note: Appropriate sport-specific questions may be substituted.</b>
      </p>
    </React.Fragment>
  );
};

export default MemoryAssesmentForm;

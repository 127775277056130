import { takeEvery, put, select, all } from "redux-saga/effects";
import { segmentRecordActionTypes } from "./segment-record-reducer.js";
import {
  segmentRecordReceived,
  segmentRecordRegistrationSent,
  segmentRecordRegistered,
} from "./segment-record-actions.js";
import {
  getSessionById,
  getSessionByIdList,
  sessionReceived,
} from "../session/session-actions";
import request from "../../my-axios.js";
import { getSegmentRecord, getSession } from "../../selectors.js";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  getSegmentRecordById: (segmentRecordId) =>
    `api/v1/results/${segmentRecordId}`,
  createSegmentRecord: (sessionId) => `api/v1/${sessionId}/save-segment-record`,
  getSegmentRecordsByIdList: () => `api/v1/results-list`,
  getHistoryById: (athleteId) => `api/v1/athlete/${athleteId}/segment-records`,
  getSegmentRecordHeaderById: (segmentRecordId) =>
    `api/v1/segmentRecord/${segmentRecordId}`,
  getSegmentRecordHeaderByIdList: () => `api/v1/session-list`,
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* segmentRecordSagas() {
  yield all([
    takeEvery(
      segmentRecordActionTypes.getSegmentRecordById,
      getSegmentRecordByIdSaga
    ),
    takeEvery(
      segmentRecordActionTypes.createSegmentRecord,
      createSegmentRecordSaga
    ),
    takeEvery(
      segmentRecordActionTypes.getSegmentRecordsByIdList,
      getSegmentRecordsByIdListSaga
    ),
    takeEvery(
      segmentRecordActionTypes.getHistoryById,
      getAthleteSegmentRecordHistoryByIdSaga
    ),
    takeEvery(
      segmentRecordActionTypes.getSegmentRecordHeaderById,
      getSegmentRecordHeaderByIdSaga
    ),
    takeEvery(
      segmentRecordActionTypes.getSegmentRecordHeaderByIdList,
      getSegmentRecordHeaderByIdListSaga
    ),
    takeEvery(
      segmentRecordActionTypes.getSessionsBySegmentRecordIdList,
      getSessionsBySegmentRecordIdListSaga
    ),
  ]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* getSegmentRecordByIdSaga(action) {
  const req = request.get(
    ENDPOINTS.getSegmentRecordById(action.segmentRecordId)
  );
  const response = yield req;
  // console.log("segmentRecord response", response);
  if (response.data.segmentRecord.id == action.segmentRecordId) {
    yield put(
      segmentRecordReceived(response.data.segmentRecord, action.segmentRecordId)
    );
  } else {
    console.error("Invalid Records! Received:");
    console.error(response.data.segmentRecord);
  }
}

function* createSegmentRecordSaga(action) {
  const id = `${action.id}-${action.athleteId}`;
  try {
    const req = request.post(ENDPOINTS.createSegmentRecord(null), {
      athleteId: action.athleteId,
      trainerId: action.trainerId,
      data: action.segmentRecord,
    });
    yield put(segmentRecordRegistrationSent(id));
    // console.log(action.segmentRecord);
    const response = yield req;
    // console.log("create segmentRecord response", response);
    yield put(segmentRecordRegistered(id));
  } catch (exception) {
    console.error(exception);
    yield put(segmentRecordRegistered(id, ["Error"]));
  }
}

function* getSegmentRecordsByIdListSaga(action) {
  try {
    const req = request.post(ENDPOINTS.getSegmentRecordsByIdList(), {
      segmentRecordIds: action.segmentRecordIds,
    });
    const response = yield req;
    // console.log("segmentRecord response", response);
    yield put(segmentRecordReceived(response.data.segmentRecords));
  } catch (exception) {
    window.testError = exception;
    console.error("Invalid Records! Received:");
    console.error(window.testError);
  }
}

function* getAthleteSegmentRecordHistoryByIdSaga(action) {
  try {
    const req = request.get(ENDPOINTS.getHistoryById(action.athleteId));
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(sessionReceived(response.data.sessions));
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      const response = JSON.parse(exception.response.text);
      console.error(response);
    }
  }
}

function* getSegmentRecordHeaderByIdSaga(action) {
  try {
    const req = request.get(
      ENDPOINTS.getSegmentRecordHeaderById(action.segmentRecordId)
    );
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(segmentRecordReceived(response.data.segmentRecord));
    const segmentRecord = yield select(
      getSegmentRecord,
      action.segmentRecordId
    );
    const session = yield select(getSession, segmentRecord.session_id);
    if (!session) {
      yield put(getSessionById(segmentRecord.session_id));
    }
  } catch (exception) {
    window.testError = exception;
  }
}

function* getSegmentRecordHeaderByIdListSaga(action) {
  try {
    const req = request.post(ENDPOINTS.getSegmentRecordHeaderByIdList(), {
      segmentRecordIds: action.segmentRecordIds,
    });
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(segmentRecordReceived(response.data.segmentRecords));
  } catch (exception) {
    window.testError = exception;
  }
}

function* getSessionsBySegmentRecordIdListSaga(action) {
  try {
    const segmentRecords = yield all(
      action.segmentRecordIds.map((segmentRecordId) =>
        select(getSegmentRecord, segmentRecordId)
      )
    );
    const sessionIds = segmentRecords.map((record) => record.sessionId);
    const uniqueSessionIds = sessionIds.filter((x, i, a) => a.indexOf(x) == i);
    let neededSessions = [];
    for (let sessionId of uniqueSessionIds) {
      const session = yield select(getSession, sessionId);
      if (typeof session === "undefined") {
        neededSessions.push(sessionId);
      }
    }
    yield put(getSessionByIdList(neededSessions));
  } catch (exception) {
    console.error(exception);
  }
}

export const sagas = { segmentRecordSagas };

import React from "react";

import UserDropDown from "../user-dropdown/user-dropdown";
import NavMenu from "../nav-menu/nav-menu";

import "./app-bar.scss";
import DigijumpLogo from "/resources/svg/digijump-logo.svg";

class AppBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="app-bar">
        <div className="app-bar__menu__container">
          <NavMenu />
        </div>
        <div className="app-bar__page-name__container">
          <img className="app-bar__logo" src={DigijumpLogo}></img>
          <h1>{this.props.pageName}</h1>
        </div>
        <div className="user-dropdown__container">
          <UserDropDown />
        </div>
      </div>
    );
  }
}

export default AppBar;

import React from "react";
import { connect } from "react-redux";
import { login } from "../account-actions";

import CommonButton from "../../common/button/common-button.tsx";
import DigijumpLogo from "/resources/svg/digijump-logo.svg";
import CommonFormInput from "../../common/form/input/common-form-input";

import "./account-login.scss";
import { map } from "lodash";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formVals: {
        email: "",
        password: "",
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    if (!this.state.formVals.email) {
      return;
    }
    this.props.login(this.state.formVals.email, this.state.formVals.password);
    this.setState({ formVals: { email: "", password: "" } });
  }

  render() {
    const handleChange = (event) => {
      const formVals = {
        ...this.state.formVals,
        [event.target.name]: event.target.value,
      };
      this.setState({ formVals });
    };

    return (
      <div className="account-login-form__container">
        <div className="account-login-form__heading">
          <img
            className="account-login-form__heading__logo"
            src={DigijumpLogo}
          ></img>
          <div className="account-login-form__heading__text">
            Roster Application
          </div>
        </div>
        <form className="account-login-form" onSubmit={this.onSubmit}>
          <CommonFormInput
            value={this.state.formVals.email}
            id="user-email"
            name="email"
            type="email"
            onChange={handleChange}
            label="Email"
          />
          <CommonFormInput
            value={this.state.formVals.password}
            id="user-password"
            name="password"
            type="password"
            onChange={handleChange}
            label="Password"
          />
          <div className="account-login-form__status-message__container">
            <div
              className={`account-login-form__status-message account-login-form__status-message--${this.props.loginAttemptStatusType}`}
            >
              {this.props.loginAttemptStatusMessage}
            </div>
          </div>
          <div className="account-login-form__submit-button__container">
            <input style={{ display: "none" }} type="submit" value="Submit" />
            <CommonButton modifiers={["red"]} onClick={this.onSubmit}>
              Submit
            </CommonButton>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const loginAttemptStatusMessage =
    state.accountReducer.loginAttemptStatusMessage;
  const loginAttemptStatusType = state.accountReducer.loginAttemptStatusType;
  return { loginAttemptStatusMessage, loginAttemptStatusType };
};

export default connect(mapStateToProps, { login })(LoginForm);

import React from "react";
import { connect } from "react-redux";
import { getSelectedOrganizationMachines } from "../../../selectors";
import CommonStyledDropdown from "../../common/styled-dropdown/common-styled-dropdown";
import { selectMachine } from "../../machine/machine-actions";

import "./machine-picker.scss";

class MachinePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedOrganizationId !== prevProps.selectedOrganizationId
    ) {
      this.props.selectMachine(Object.keys(this.props.machines)[0]);
    }
  }

  render() {
    return (
      <CommonStyledDropdown
        entities={this.props.machines}
        label="Machine"
        defaultValue={this.props.selectedMachineId}
        onChange={this.props.selectMachine}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let machines = getSelectedOrganizationMachines(state);
  let selectedMachineId = state.machineReducer.selectedMachineId;
  const selectedOrganizationId = state.accountReducer.selectedOrganization;

  return {
    machines,
    selectedMachineId,
    selectedOrganizationId,
  };
};

export default connect(mapStateToProps, { selectMachine })(MachinePicker);

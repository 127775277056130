import React, { useMemo } from "react";
import { connect } from "react-redux";
import CommonStyledDropdown from "../../common/styled-dropdown/common-styled-dropdown";
import { selectOrganization } from "../../account/account-actions";
import { useEffect } from "react";

interface OrganizationPickerProps {
  organizations: any;
  union?: any;
  selectedOrganizationId: string;
  selectOrganization: (id) => void;
}

const OrganizationPicker = (props: OrganizationPickerProps): JSX.Element => {
  const { selectOrganization, selectedOrganizationId } = props;
  const availableOrganizations = useMemo(() => {
    let available = {};
    if (props.union) {
      props.union.forEach((org) => {
        if (props.organizations[org.id]) {
          available[org.id] = org;
        }
      });
    } else {
      available = props.organizations;
    }
    return available;
  }, [props.union, props.organizations]);

  useEffect(() => {
    if (!availableOrganizations[selectedOrganizationId]) {
      selectOrganization(Object.keys(availableOrganizations)[0]);
    }
  }, [availableOrganizations, selectedOrganizationId, selectOrganization]);

  if (Object.keys(availableOrganizations).length <= 1) {
    return null;
  }

  return (
    <CommonStyledDropdown
      entities={availableOrganizations}
      label="Organization"
      defaultValue={props.selectedOrganizationId}
      onChange={props.selectOrganization}
    />
  );
};

const mapStateToProps = (state) => {
  const organizations = state.accountReducer.organizations;
  const selectedOrganizationId = state.accountReducer.selectedOrganization;

  return {
    organizations,
    selectedOrganizationId,
  };
};

export default connect(mapStateToProps, { selectOrganization })(
  OrganizationPicker
);

import { connect } from "react-redux";
// import { History } from "history";
import React, { useEffect } from "react";
import { getAthlete } from "../selectors";
import { getAthleteById } from "../modules/athlete/athlete-actions";
import AthleteEditForm from "../modules/athlete/form/edit-form/athlete-edit-form";
import CommonCenterBanner from "../modules/common/banner/center/common-center-banner";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";
import AppBar from "../modules/common/app-bar/app-bar";
import OrganizationPicker from "../modules/organization/picker/organization-picker";

interface History {
  push: (route: string) => void;
}
interface AthleteProfilePageProps {
  athlete: any;
  athleteId: string;
  getAthleteById: (athleteId: string) => void;
  organizationId: string;
  history: History;
}

const AthleteProfilePage = (props: AthleteProfilePageProps): JSX.Element => {
  useEffect(() => {
    if (!props.athlete) {
      props.getAthleteById(props.athleteId);
      // console.log("requesting ath");
    }
  }, []);

  //useEffect(() => {
  //  props.getAthleteUserCode(props.athleteId, props.organizationId);
  //}, [props.organizationId, props.athleteId]);

  // console.log("ATHE", props.athleteId);

  let athlete = { ...props.athlete };
  if (Object.keys(athlete).length == 0) {
    athlete = { name: "", id: "" };
  }

  const backRoute = `/athlete-log/${athlete.id}`;
  const union = props.athlete ? props.athlete.organizations : undefined;
  return (
    <div className="page-wrapper">
      <AppBar pageName="Athlete Profile" />
      <CommonCenterBanner>
        <div>Athlete:</div>
        <div>{athlete.name}</div>
        <div style={{ marginTop: "20px" }}>
          <OrganizationPicker union={union} />
        </div>
      </CommonCenterBanner>
      <AthleteEditForm
        organizationId={props.organizationId}
        athlete={athlete}
        onSuccessRedirectTo={backRoute}
        onCancel={() => props.history.push(backRoute)}
      />
      <UserFeedbackDrawer />
    </div>
  );
};

export { AthleteProfilePage };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state, props) => {
  const organizationId = state.accountReducer.selectedOrganization;
  const athleteId = props.match.params.athleteId;
  const athlete = getAthlete(state, athleteId);
  //console.log('ATHLETE', athlete);

  //console.log("SESSIONS", sessions);
  return { athleteId, athlete, organizationId };
};

export default connect(mapStateToProps, { getAthleteById })(AthleteProfilePage);

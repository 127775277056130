import React from "react";

import "./common-center-banner.scss";

class CommonCenterBanner extends React.Component {
  render() {
    return <div className="common-center-banner">{this.props.children}</div>;
  }
}

export default CommonCenterBanner;

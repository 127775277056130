import axios from "axios";
import _ from "lodash";

/*parcel automatically grabs env variables, and ironically
using dotenv will cancel that out for some stupid reason
so these are here in case we need to switch builders*/
// import dotenv from "dotenv";
// dotenv.config();

// eslint-disable-next-line no-undef
let baseUrl = process.env.API_URL;
if (!baseUrl || baseUrl == undefined) {
  baseUrl = "https://api.digijump.com/";
}

// console.log("BASE URL", baseUrl);

export const apiUrl = (url) => baseUrl + url;

export default {
  getToken() {
    let token = JSON.parse(localStorage.getItem("token"));
    return token;
  },

  setToken(newToken) {
    let token = newToken;
    localStorage.setItem("token", JSON.stringify(token));
  },

  resetToken() {
    let token = null;
    localStorage.setItem("token", JSON.stringify(token));
  },

  get(url, apiCall = true) {
    return this.makeRequest("get", url, null, apiCall);
  },

  post(url, data, apiCall = true) {
    return this.makeRequest("post", url, data, apiCall);
  },

  put(url, data, apiCall = true) {
    return this.makeRequest("put", url, data, apiCall);
  },

  delete(url, apiCall = true) {
    return this.makeRequest("delete", url, null, apiCall);
  },

  makeRequest(verb, urlFunc, data, apiCall = true) {
    let url = urlFunc;
    if (_.isFunction(urlFunc)) url = urlFunc();

    if (apiCall) {
      url = apiUrl(url);
    }
    let token = this.getToken();

    const config = {};
    if (token) {
      config.headers = { Authorization: "Bearer " + token };
    }
    let secondArg = data;
    let thirdArg = config;
    if (!data) {
      secondArg = config;
      thirdArg = undefined;
    }

    const request = axios[verb](url, secondArg, thirdArg);

    return request;
  },
};

import { calculateBandChanges } from "@unbound/digijump-sessiondata-library";

function getRecordHRLineDatum(sample) {
  if (sample.timestamp) {
    const datum = { x: new Date(sample.timestamp), y: sample.heartBPM };
    return datum;
  } else {
    return null;
  }
}
function getRecordHRLineData(array) {
  return array.map((sample) => getRecordHRLineDatum(sample));
}

function getRecordLineDatum(sample, selectedStat, offset, enableLabel) {
  let datum;
  if (enableLabel) {
    datum = {
      x: new Date(sample.timestamp),
      y: sample[selectedStat] - offset,
      label: undefined,
    };
    if (sample["label"]) {
      datum.label = sample["label"];
    }
  } else {
    datum = {
      x: new Date(sample.timestamp).getTime(),
      y: sample[selectedStat] - offset,
    };
  }
  if (!datum.y || datum.y > 10000) {
    datum.y = 0;
  } else {
    return datum;
  }
  return datum;
}

function getRecordLineData(
  array,
  selectedStat,
  enableLabel = true,
  offset = 0
) {
  return array.map((sample) =>
    getRecordLineDatum(sample, selectedStat, enableLabel, offset)
  );
}

function getJumpEvents(segmentRecord, segType) {
  const jumpEvents = segmentRecord.events.filter(
    (event) => event.segType == segType
  );
  const jumpStartEvents = jumpEvents.filter(
    (event) => event.eventType == "START_TIME"
  );
  const jumpEndEvents = jumpEvents.filter(
    (event) => event.eventType == "END_TIME"
  );
  const jumpStartPauseEvents = jumpEvents.filter(
    (event) => event.eventType == "PAUSE"
  );
  const jumpUnpauseEvents = jumpEvents.filter(
    (event) => event.eventType == "UNPAUSE"
  );
  const jumpPauseEvents = jumpStartPauseEvents.map((pauseEvent, pauseIndex) => {
    return {
      pauseStart: pauseEvent,
      pauseEnd: jumpUnpauseEvents[pauseIndex],
    };
  });

  return { jumpStartEvents, jumpEndEvents, jumpPauseEvents };
}

function getJumpEventsByDataType(segmentRecord, segDataType) {
  const jumpEvents = segmentRecord.events.filter(
    (event) => event.segDataType == segDataType
  );
  const jumpStartEvents = jumpEvents.filter(
    (event) => event.eventType == "START_TIME"
  );
  const jumpEndEvents = jumpEvents.filter(
    (event) => event.eventType == "END_TIME"
  );
  const jumpStartPauseEvents = jumpEvents.filter(
    (event) => event.eventType == "PAUSE"
  );
  const jumpUnpauseEvents = jumpEvents.filter(
    (event) => event.eventType == "UNPAUSE"
  );
  const jumpPauseEvents = jumpStartPauseEvents.map((pauseEvent, pauseIndex) => {
    return {
      pauseStart: pauseEvent,
      pauseEnd: jumpUnpauseEvents[pauseIndex],
    };
  });

  return { jumpStartEvents, jumpEndEvents, jumpPauseEvents };
}

function getJumpOffset(segmentRecord, segmentSampleIndexes, segIndex) {
  let segmentJumpOffset = 0;
  console.log(
    "HIYA",
    segmentRecord,
    segmentRecord.segmentRecordSamples[segmentSampleIndexes[0].first]
  );

  console.log("here?", segmentRecord.segmentRecordSamples.length);
  segmentJumpOffset +=
    segmentRecord.segmentRecordSamples[segmentSampleIndexes[0].first].jumps;
  if (segIndex != 0) {
    segmentJumpOffset += segmentSampleIndexes.reduce(
      (acc, unused, loopIndex) => {
        if (segIndex > loopIndex) {
          const index = segIndex - loopIndex;
          return (
            acc +
            segmentRecord.segmentRecordSamples[
              segmentSampleIndexes[index].first
            ].jumps -
            segmentRecord.segmentRecordSamples[
              segmentSampleIndexes[index - 1].last
            ].jumps
          );
        } else return acc + 0;
      },
      0
    );
  }

  return segmentJumpOffset;
}

function getJumpsGoodOffset(segmentRecord, segmentSampleIndexes, segIndex) {
  let segmentJumpsGoodOffset = 0;
  segmentJumpsGoodOffset +=
    segmentRecord.segmentRecordSamples[segmentSampleIndexes[0].first].jumpsGood;
  if (segIndex != 0) {
    segmentJumpsGoodOffset += segmentSampleIndexes.reduce(
      (acc, unused, loopIndex) => {
        if (segIndex > loopIndex) {
          const index = segIndex - loopIndex;
          return (
            acc +
            segmentRecord.segmentRecordSamples[
              segmentSampleIndexes[index].first
            ].jumpsGood -
            segmentRecord.segmentRecordSamples[
              segmentSampleIndexes[index - 1].last
            ].jumpsGood
          );
        } else return acc + 0;
      },
      0
    );
  }

  return segmentJumpsGoodOffset;
}

function calculateValidJumps({
  segmentRecord,
  filteredSamples,
  segmentJumpOffset,
  segmentJumpsGoodOffset,
  filterRate,
  segIndex,
  jumpStartEvents,
  jumpPauseEvents,
}) {
  const segmentSamples = filteredSamples.map((filteredSample, sampleIndex) => {
    let jumpOffset = segmentJumpOffset;
    let jumpsGoodOffset = segmentJumpsGoodOffset;
    const segmentSample = { ...filteredSample };
    if (sampleIndex >= 0 && sampleIndex < filterRate) {
      segmentSample.label = `Portion ${segIndex + 1}`;
    }
    if (
      segIndex == jumpStartEvents.length - 1 &&
      sampleIndex >= filteredSamples.length - filterRate
    ) {
      segmentSample.label = `End ${segIndex + 1}`;
    }
    jumpPauseEvents.forEach((pauseEvent) => {
      const lastUnpausedReadingJumps =
        segmentRecord.segmentRecordSamples[
          pauseEvent.pauseStart.lastUnpausedReading
        ].jumps;
      const lastPausedReadingJumps =
        segmentRecord.segmentRecordSamples[
          pauseEvent.pauseEnd.lastPausedReading
        ].jumps;
      const lastUnpausedReadingJumpsGood =
        segmentRecord.segmentRecordSamples[
          pauseEvent.pauseStart.lastUnpausedReading
        ].jumpsGood;
      const lastPausedReadingJumpsGood =
        segmentRecord.segmentRecordSamples[
          pauseEvent.pauseEnd.lastPausedReading
        ].jumpsGood;
      if (segmentSample.timestamp > pauseEvent.pauseStart.timestamp) {
        if (segmentSample.timestamp < pauseEvent.pauseEnd.timestamp) {
          jumpOffset += segmentSample.jumps - lastUnpausedReadingJumps;
          jumpsGoodOffset +=
            segmentSample.jumpsGood - lastUnpausedReadingJumpsGood;
        } else {
          jumpOffset += lastPausedReadingJumps - lastUnpausedReadingJumps;
          jumpsGoodOffset +=
            lastPausedReadingJumpsGood - lastUnpausedReadingJumpsGood;
        }
      }
    });
    segmentSample.validJumps = segmentSample.jumps - jumpOffset + 1;
    segmentSample.validJumpsGood = segmentSample.jumpsGood - jumpsGoodOffset;
    segmentSample.jumpPercentage =
      (segmentSample.validJumpsGood / segmentSample.validJumps) * 100;
    return segmentSample;
  });

  return segmentSamples;
}

function processJumpRecordSegments({
  segmentRecord,
  segType,
  filterRate,
  segDataType = null,
}) {
  //extract relavent jump events
  // const { jumpStartEvents, jumpEndEvents, jumpPauseEvents } = getJumpEvents(
  //   segmentRecord,
  //   segType
  // );
  let jumpStartEvents, jumpEndEvents, jumpPauseEvents;

  if (segType) {
    const events = getJumpEvents(segmentRecord, segType);
    jumpStartEvents = events.jumpStartEvents;
    jumpEndEvents = events.jumpEndEvents;
    jumpPauseEvents = events.jumpPauseEvents;
  } else if (segDataType) {
    const events = getJumpEventsByDataType(segmentRecord, segDataType);
    jumpStartEvents = events.jumpStartEvents;
    jumpEndEvents = events.jumpEndEvents;
    jumpPauseEvents = events.jumpPauseEvents;
  }

  console.log({ jumpStartEvents, jumpEndEvents, jumpPauseEvents });

  const segmentSampleIndexes = [];
  const jumpSegments = jumpStartEvents.map((startEvent, segIndex) => {
    const endEvent = jumpEndEvents[segIndex];
    const pauses = jumpPauseEvents.filter(
      (pauseEvent) =>
        pauseEvent.pauseStart.timestamp > startEvent.timestamp &&
        pauseEvent.pauseEnd.timestamp < endEvent.timestamp
    );
    const filteredSamples = segmentRecord.segmentRecordSamples.filter(
      (segmentRecordSample) =>
        segmentRecordSample.timestamp > startEvent.timestamp &&
        segmentRecordSample.timestamp < endEvent.timestamp
    );
    const indexOfSegmentLastSample =
      segmentRecord.segmentRecordSamples.findIndex(
        (sample) =>
          sample.timestamp ==
          filteredSamples[filteredSamples.length - 1].timestamp
      );
    const indexOfSegmentFirstSample =
      segmentRecord.segmentRecordSamples.findIndex(
        (sample) => sample.timestamp == filteredSamples[0].timestamp
      );
    segmentSampleIndexes.push({
      first: indexOfSegmentFirstSample,
      last: indexOfSegmentLastSample,
    });

    //Calculate and remove jumps between jump segments
    const segmentJumpOffset = getJumpOffset(
      segmentRecord,
      segmentSampleIndexes,
      segIndex
    );
    const segmentJumpsGoodOffset = getJumpsGoodOffset(
      segmentRecord,
      segmentSampleIndexes,
      segIndex
    );

    //Calculate valid jumps for each sample
    const segmentSamples = calculateValidJumps({
      segmentRecord,
      filteredSamples,
      segmentJumpOffset,
      segmentJumpsGoodOffset,
      filterRate,
      segIndex,
      jumpStartEvents,
      jumpPauseEvents,
    });
    return {
      startEvent: startEvent,
      endEvent,
      pauses,
      segmentSamples,
    };
  });

  const jumpSamples = jumpSegments.reduce(
    (result, segmentEvent) => result.concat(segmentEvent.segmentSamples),
    []
  );
  const jumpSamplesInRange = jumpSamples.filter(
    (sample, index) => index % filterRate == 0
  );

  const xAxis = jumpSamplesInRange.map((sample) => {
    return {
      id: sample.timestamp,
      label: sample.label,
      date: new Date(sample.timestamp).toString(),
    };
  });

  return { jumpSamplesInRange, xAxis };
}

function getResistanceEvents(segmentRecord, segType) {
  const resistanceEvents = segmentRecord.events.filter(
    (event) => event.segType == segType
  );
  const resistanceStartEvents = resistanceEvents.filter(
    (event) => event.eventType == "START_TIME"
  );
  const resistanceEndEvents = resistanceEvents.filter(
    (event) => event.eventType == "END_TIME"
  );
  const repStartEvents = segmentRecord.events.filter(
    (event) => event.eventType == "BEGIN_REP"
  );
  const repEndEvents = segmentRecord.events.filter(
    (event) => event.eventType == "END_REP"
  );
  return {
    resistanceStartEvents,
    resistanceEndEvents,
    repStartEvents,
    repEndEvents,
  };
}

function getResistanceEventsByDataType(segmentRecord, segDataType) {
  const resistanceEvents = segmentRecord.events.filter(
    (event) => event.segDataType == segDataType
  );
  const resistanceStartEvents = resistanceEvents.filter(
    (event) => event.eventType == "START_TIME"
  );
  const resistanceEndEvents = resistanceEvents.filter(
    (event) => event.eventType == "END_TIME"
  );
  const repStartEvents = segmentRecord.events.filter(
    (event) => event.eventType == "BEGIN_REP"
  );
  const repEndEvents = segmentRecord.events.filter(
    (event) => event.eventType == "END_REP"
  );
  return {
    resistanceStartEvents,
    resistanceEndEvents,
    repStartEvents,
    repEndEvents,
  };
}

function processResistanceRecordSegments({
  segmentRecord,
  segType,
  filterRate,
  segDataType = null,
}) {
  let resistanceStartEvents, resistanceEndEvents, repStartEvents, repEndEvents;
  if (segType) {
    const events = getResistanceEvents(segmentRecord, segType);
    resistanceStartEvents = events.resistanceStartEvents;
    resistanceEndEvents = events.resistanceEndEvents;
    repStartEvents = events.repStartEvents;
    repEndEvents = events.repEndEvents;
  } else if (segDataType) {
    const events = getResistanceEventsByDataType(segmentRecord, segDataType);
    resistanceStartEvents = events.resistanceStartEvents;
    resistanceEndEvents = events.resistanceEndEvents;
    repStartEvents = events.repStartEvents;
    repEndEvents = events.repEndEvents;
  }

  const changingBands = {
    lowerBandForce: 0,
    centerBandForce: 0,
    highBandForce: 0,
    topBandForce: 0,
  };
  const resistanceSegments = resistanceStartEvents.map(
    (startEvent, segIndex) => {
      const endEvent = resistanceEndEvents[segIndex];
      const filteredSamples = segmentRecord.segmentRecordSamples.filter(
        (segmentRecordSample) =>
          segmentRecordSample.timestamp > startEvent.timestamp &&
          segmentRecordSample.timestamp < endEvent.timestamp
      );
      const HRSamples = segmentRecord.heartBPMSamples.filter(
        (heartBPMSample) =>
          heartBPMSample.timestamp > startEvent.timestamp &&
          heartBPMSample.timestamp < endEvent.timestamp
      );
      const segmentSamples = filteredSamples.map((filteredSample) => {
        const segmentSample = { ...filteredSample };
        const { bandChanging, callibratedBandReading } = calculateBandChanges(
          segmentSample,
          segmentRecord
        );
        if (callibratedBandReading > 0) {
          changingBands[bandChanging]++;
        }
        return segmentSample;
      });
      const segChangingBand = Object.keys(changingBands).reduce((prev, cur) =>
        changingBands[prev] >= changingBands[cur] ? prev : cur
      );
      // console.log(segChangingBand);
      return {
        startEvent,
        endEvent,
        segChangingBand,
        HRSamples,
        segmentSamples,
      };
    }
  );
  const samples = resistanceSegments.reduce(
    (result, segmentEvent) => result.concat(segmentEvent.segmentSamples),
    []
  );
  //    const heartrateSamples = resistanceSegments.reduce((result, segmentEvent) => result.concat(segmentEvent.HRSamples), []);
  const filteredSamples = samples.filter(
    (sample, index) => index % filterRate == 0
  );
  const reversedSamples = [...filteredSamples].reverse();
  repStartEvents.forEach((event, index) => {
    const searchSamples = filteredSamples;
    const startIndex = searchSamples.findIndex(
      (sample) => sample.timestamp >= event.timestamp
    );
    if (startIndex >= 0 && filteredSamples[startIndex]) {
      filteredSamples[startIndex].label = `R${index + 1}`;
      //   console.log(filteredSamples[startIndex].label);
    } else {
      // console.log("no start to ", index + 1, event);
    }
    const endIndex =
      reversedSamples.length -
      reversedSamples.findIndex(
        (sample) => sample.timestamp <= repEndEvents[index].timestamp
      ) -
      1;
    if (endIndex >= 0 && filteredSamples[endIndex]) {
      filteredSamples[endIndex].label = `RS${index + 1}`;
      //   console.log(filteredSamples[endIndex].label);
    } else {
      // console.log("no end to ", index + 1, event);
    }
    //   console.log('rep', startIndex, endIndex)
  });

  const xAxis = filteredSamples.map((sample) => {
    return {
      id: sample.timestamp,
      label: sample.label,
      date: new Date(sample.timestamp).getTime(),
    };
  });

  return { filteredSamples, xAxis, resistanceSegments };
}

export {
  getRecordHRLineData,
  getRecordLineData,
  processJumpRecordSegments,
  processResistanceRecordSegments,
};

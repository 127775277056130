import React from "react";

import "./common-split-banner.scss";

class CommonSplitBanner extends React.Component {
  render() {
    return (
      <div className="common-split-banner">
        <div className="common-split-banner__left-component__container">
          {this.props.leftComponent}
        </div>
        <div className="common-split-banner__right-component__container">
          {this.props.rightComponent}
        </div>
      </div>
    );
  }
}

export default CommonSplitBanner;

import { normalize } from "normalizr";
import { arrayWrap } from "../../easy-reducer.js";
import { sessionSchema } from "../../normalizr-schema.js";
import { sessionActionTypes } from "./session-reducer";
import { normalizedDataReceived } from "../app/app-actions.js";

export function sessionReceived(session) {
  const sessions = normalize(arrayWrap(session), [sessionSchema]);
  return normalizedDataReceived(sessions.entities);
}

export function startSession(athleteId, trainerId, machineId, data) {
  return {
    type: sessionActionTypes.start,
    athleteId,
    trainerId,
    machineId,
    data,
  };
}

export function setCurrentSession(sessionId) {
  return {
    type: sessionActionTypes.setCurrent,
    sessionId,
  };
}

export function getCurrentSession(machineId) {
  return { type: sessionActionTypes.getCurrent, machineId };
}

export function getSessionById(sessionId) {
  return { type: sessionActionTypes.getSessionById, sessionId };
}

export function getSessionByIdList(sessionIds) {
  return { type: sessionActionTypes.getSessionByIdList, sessionIds };
}

export function endCurrentSession(machineId) {
  return { type: sessionActionTypes.endCurrent, machineId };
}

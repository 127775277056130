import React from "react";
import { connect } from "react-redux";
import SegmentRecordResultsGraph from "../../results-graph";
import {
  getRecordLineData,
  getRecordHRLineData,
  processResistanceRecordSegments,
} from "../../graph_utilities";
import { getExerciseDataTypes } from "../../../../../../selectors";

class SegmentRecordResistanceRecordGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterRate: 1,
      lines: {},
      xAxis: [],
    };
  }

  componentDidMount() {
    const segmentRecord = this.props.segmentRecord;
    const filterRate = this.state.filterRate;
    const { filteredSamples, resistanceSegments, xAxis } =
      processResistanceRecordSegments({
        segmentRecord,
        segType: null,
        filterRate,
        segDataType: this.props.exerciseDataTypes.RESISTANCE,
      });

    const heartBPMSamples = segmentRecord.heartBPMSamples;

    const lines = {
      lowerBandForce: {
        data: getRecordLineData(filteredSamples, "lowerBandForce", false),
        color: "#796FF3",
        duration: 2000,
        lable: "Lower Band Force",
      },
      centerBandForce: {
        data: getRecordLineData(filteredSamples, "centerBandForce", false),
        color: "#796FF3",
        duration: 2000,
        lable: "Center Band Force",
      },
      highBandForce: {
        data: getRecordLineData(filteredSamples, "highBandForce", false),
        color: "#796FF3",
        duration: 2000,
        lable: "High Band Force",
      },
      topBandForce: {
        data: getRecordLineData(filteredSamples, "topBandForce", false),
        color: "#796FF3",
        duration: 2000,
        lable: "Top Band Force",
      },
    };

    let selectedLines = {
      heartBPM: {
        data: getRecordHRLineData(heartBPMSamples),
        color: "#CC2401",
        duration: 4000,
        label: "Heart Rate",
      },
    };
    resistanceSegments.forEach((segment) => {
      selectedLines[segment.segChangingBand] = lines[segment.segChangingBand];
    });
    //console.log('lines', selectedLines)

    this.setState({
      lines: selectedLines,
      xAxis,
    });
  }

  render() {
    const lines = this.state.lines;
    const xAxis = this.state.xAxis;
    if (xAxis.length > 0) {
      return (
        <div>
          <SegmentRecordResultsGraph
            lines={lines}
            chartLabel="Resistance Record"
            xAxis={xAxis}
            zoomable={true}
            baselineSegmentRecord={{}}
            scale="mseconds"
            history={this.props.history}
          />
        </div>
      );
    } else {
      return <div>Loading Graph...</div>;
    }
  }
}

const mapStateToProps = (state, props) => {
  const segmentRecord = state.segmentRecordReducer.entities[props.sessionId];
  const exerciseDataTypes = getExerciseDataTypes();
  return {
    segmentRecord,
    exerciseDataTypes,
  };
};

export default connect(mapStateToProps, {})(SegmentRecordResistanceRecordGraph);

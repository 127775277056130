import React from "react";
import CommonDateTimeDisplayField from "../../../common/display-field/date-time/date-time";

import "./results-card.scss";

class SegmentRecordResultsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatTime(time) {
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  }

  render() {
    let cardioResults = null;
    let resistanceResults = null;
    let fastFeetResults = null;
    let baselineResults = null;
    let segmentRecordDateTime;
    let athleteHeader;
    if (this.props.multipleAthletes) {
      athleteHeader = (
        <div style={{ textAlign: "center" }}>
          <h3>{this.props.athleteName}</h3>
        </div>
      );
    }
    if (this.props.segmentRecord) {
      segmentRecordDateTime = (
        <CommonDateTimeDisplayField
          dateTime={this.props.segmentRecord.segmentRecordStartTime}
        />
      );
    }
    if (
      this.props.resultData.resultTypes &&
      this.props.resultData.resultTypes.targetedCardio &&
      !this.props.resultData.resultTypes.baseline
    ) {
      cardioResults = (
        <table className="results-card__body__table">
          <thead>
            <tr>
              <th></th>
              <th>Cardio Results</th>
            </tr>
          </thead>
          <tbody>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Time</th>
              <td className="results-card__body__row__cell">
                {this.formatTime(
                  this.props.resultData.elapsedTargetedCardioTime
                )}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Jumps</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.jumps}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Jump %</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.jumpPercentage}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Cals</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.calories}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Max Hr</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.maxCardioHR}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Avg Hr</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.averageCardioHR}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Min Hr</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.minCardioHR}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    if (
      this.props.resultData.resultTypes &&
      this.props.resultData.resultTypes.resistance &&
      !this.props.resultData.resultTypes.baseline
    ) {
      resistanceResults = (
        <table className="results-card__body__table">
          <thead>
            <tr>
              <th></th>
              <th>Resistance Results</th>
            </tr>
          </thead>
          <tbody>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Time</th>
              <td className="results-card__body__row__cell">
                {this.formatTime(this.props.resultData.elapsedResistanceTime)}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Reps</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.totalReps}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Exercises</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.exercises}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Total Pounds</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.pounds}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Max Hr</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.maxResistanceHR}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Avg Hr</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.averageResistanceHR}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Min Hr</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.minResistanceHR}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    if (
      this.props.resultData.resultTypes &&
      this.props.resultData.resultTypes.speedFocusedCardio &&
      !this.props.resultData.resultTypes.baseline
    ) {
      fastFeetResults = (
        <table className="results-card__body__table">
          <thead>
            <tr>
              <th></th>
              <th>Speed Steps Results</th>
            </tr>
          </thead>
          <tbody>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Time</th>
              <td className="results-card__body__row__cell">
                {this.formatTime(this.props.resultData.elapsedFastFeetTime)}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Score</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.fastFeetScore}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Max HR</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.maxFastFeetHR}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Avg HR</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.averageFastFeetHR}
              </td>
            </tr>
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">Min HR</th>
              <td className="results-card__body__row__cell">
                {this.props.resultData.minFastFeetHR}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    if (
      this.props.resultData.resultTypes &&
      this.props.resultData.resultTypes.baseline
    ) {
      // console.log("BASELINE RESULTS", this.props.resultData.baselineResults);
      const baselineValues = {
        "Warm Up Cardio": {
          time: this.formatTime(
            this.props.resultData.baselineResults.warmUpCardioTime
          ),
          jumps: this.props.resultData.baselineResults.warmUpCardioJumps,
          "jump %":
            this.props.resultData.baselineResults.warmUpCardioJumpPercentage,
          Exercises: 2,
          "Max HR":
            this.props.resultData.baselineResults.warmUpCardioHRData.maxHR,
          "Avg HR":
            this.props.resultData.baselineResults.warmUpCardioHRData.avgHR,
          "Min HR":
            this.props.resultData.baselineResults.warmUpCardioHRData.minHR,
        },
        "Warm Up Resistance": {
          time: this.formatTime(
            this.props.resultData.baselineResults.warmUpResistanceTime
          ),
          reps: this.props.resultData.baselineResults.warmUpReps.totalReps,
          Exercises: 2,
          "Total Pounds":
            this.props.resultData.baselineResults.warmUpReps.totalPounds,
          "Max HR:":
            this.props.resultData.baselineResults.warmUpResistanceHRData.maxHR,
          "Avg HR":
            this.props.resultData.baselineResults.warmUpResistanceHRData.avgHR,
          "Min HR":
            this.props.resultData.baselineResults.warmUpResistanceHRData.minHR,
        },
        "Speed Steps": {
          time: this.formatTime(
            this.props.resultData.baselineResults.fastFeetTime
          ),
          score: this.props.resultData.baselineResults.fastFeetScore,
          Exercises: 2,
          "Max HR:": this.props.resultData.baselineResults.fastFeetHRData.maxHR,
          "Avg HR": this.props.resultData.baselineResults.fastFeetHRData.avgHR,
          "Min HR": this.props.resultData.baselineResults.fastFeetHRData.minHR,
        },
        "Band Torso Rotation": {
          time: this.formatTime(
            this.props.resultData.baselineResults.torsoRotationTime
          ),
          reps: this.props.resultData.baselineResults.torsoRotationReps
            .totalReps,
          Exercises: 2,
          "Total Pounds":
            this.props.resultData.baselineResults.torsoRotationReps.totalPounds,
          "Max HR:":
            this.props.resultData.baselineResults.torsoRotationHRData.maxHR,
          "Avg HR":
            this.props.resultData.baselineResults.torsoRotationHRData.avgHR,
          "Min HR":
            this.props.resultData.baselineResults.torsoRotationHRData.minHR,
        },
        "Alternating Single Leg Jump": {
          time: this.formatTime(
            this.props.resultData.baselineResults.singleJumpTime
          ),
          jumps: this.props.resultData.baselineResults.singleJumpJumps,
          "jump %":
            this.props.resultData.baselineResults.singleJumpJumpPercentage,
          Exercises: 2,
          "Max HR":
            this.props.resultData.baselineResults.singleJumpHRData.maxHR,
          "Avg HR":
            this.props.resultData.baselineResults.singleJumpHRData.avgHR,
          "Min HR":
            this.props.resultData.baselineResults.singleJumpHRData.minHR,
        },
        "Band Chest Fly": {
          time: this.formatTime(
            this.props.resultData.baselineResults.chestFlyTime
          ),
          reps: this.props.resultData.baselineResults.chestFlyReps.totalReps,
          Exercises: 2,
          "Total Pounds":
            this.props.resultData.baselineResults.chestFlyReps.totalPounds,
          "Max HR:": this.props.resultData.baselineResults.chestFlyHRData.maxHR,
          "Avg HR": this.props.resultData.baselineResults.chestFlyHRData.avgHR,
          "Min HR": this.props.resultData.baselineResults.chestFlyHRData.minHR,
        },
        "Warm Down": {
          time: this.formatTime(
            this.props.resultData.baselineResults.warmDownTime
          ),
          jumps: this.props.resultData.baselineResults.warmDownJumps,
          "jump %":
            this.props.resultData.baselineResults.warmDownJumpPercentage,
          Exercises: 1,
          "Max HR": this.props.resultData.baselineResults.warmDownHRData.maxHR,
          "Avg HR": this.props.resultData.baselineResults.warmDownHRData.avgHR,
          "Min HR": this.props.resultData.baselineResults.warmDownHRData.minHR,
        },
      };

      let baselineArray = [];

      for (const [title, values] of Object.entries(baselineValues)) {
        let valueArray = [];
        for (const [resultTitle, resultValue] of Object.entries(values)) {
          valueArray.push(
            <tr className="results-card__body__row">
              <th className="results-card__body__row__header">{resultTitle}</th>
              <td className="results-card__body__row__cell">{resultValue}</td>
            </tr>
          );
        }
        baselineArray.push(
          <React.Fragment>
            <table className="results-card__body__table">
              <thead>
                <tr>
                  <th></th>
                  <th>{title}</th>
                </tr>
              </thead>
              <tbody>{valueArray}</tbody>
            </table>
          </React.Fragment>
        );
      }

      baselineResults = baselineArray;
    }
    return (
      <div className="results-card">
        <div className="results-card__header">{segmentRecordDateTime}</div>
        <div className="results-card__body">
          {athleteHeader}
          <div>{cardioResults}</div>
          <div>{resistanceResults}</div>
          <div>{fastFeetResults}</div>
          <div>{baselineResults}</div>
        </div>
      </div>
    );
  }
}

export default SegmentRecordResultsCard;

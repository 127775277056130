import React from "react";
import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";

const SCAT5Info = (): JSX.Element => {
  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h1 style={{ color: "#CC2401" }}>WHAT IS THE SCAT5?</h1>
      <p>
        <b style={{ color: "#CC2401" }}>
          The SCAT5 is a standardized tool for evaluating concussions designed
          for use by physicians and licensed healthcare professionals1 . The
          SCAT5 cannot be performed correctly in less than 10 minutes.
        </b>
      </p>
      <p>
        If you are not a physician or licensed healthcare professional, please
        use the Concussion Recognition Tool 5 (CRT5). The SCAT5 is to be used
        for evaluating athletes aged 13 years and older. For children aged 12
        years or younger, please use the Child SCAT5.
      </p>
      <p>
        Preseason SCAT5 baseline testing can be useful for interpreting
        post-injury test scores, but is not required for that purpose.Detailed
        instructions for use of the SCAT5 are provided on page 7. Please read
        through these instructions carefully before testing the athlete. Brief
        verbal instructions for each test are given in italics. The only
        equipment required for the tester is a watch or timer.
      </p>
      <p>
        <b style={{ color: "#CC2401" }}>
          This tool may be freely copied in its current form for distribution to
          individuals, teams, groups and organizations. It should not be altered
          in any way, re-branded or sold for commercial gain. Any revision,
          translation or reproduction in a digital form requires specific
          approval by the Concussion in Sport Group.
        </b>
      </p>
      <h1 style={{ color: "#CC2401" }}>Recognise and Remove</h1>
      <p>
        A head impact by either a direct blow or indirect transmission of force
        can be associated with a serious and potentially fatal brain injury. If
        there are significant concerns, including any of the red flags listed in
        Box 1, then activation of emergency procedures and urgent transport to
        the nearest hospital should be arranged.
      </p>
      <h3>Key points</h3>
      <ul style={{ color: "#CC2401" }}>
        <li>
          Any athlete with suspected concussion should be REMOVED FROM PLAY,
          medically assessed and monitored for deterioration. No athlete
          diagnosed with concussion should be returned to play on the day of
          injury.
        </li>
        <li>
          If an athlete is suspected of having a concussion and medical
          personnel are not immediately available, the athlete should be
          referred to a medical facility for urgent assessment.
        </li>
        <li>
          Athletes with suspected concussion should not drink alcohol, use
          recreational drugs and should not drive a motor vehicle until cleared
          to do so by a medical professional.
        </li>
        <li>
          Concussion signs and symptoms evolve over time and it is important to
          consider repeat evaluation in the assessment of concussion.
        </li>
        <li>
          The diagnosis of a concussion is a clinical judgment, made by a
          medical professional. The SCAT5 should NOT be used by itself to make,
          or exclude, the diagnosis of concussion. An athlete may have a
          concussion even if their SCAT5 is “normal”.
        </li>
      </ul>
      <h3>Remember:</h3>
      <ul>
        <li>
          The basic principles of first aid (danger, response, airway,
          breathing, circulation) should be followed.
        </li>
        <li>
          {" "}
          Do not attempt to move the athlete (other than that required for
          airway management) unless trained to do so
        </li>
        <li>
          Assessment for a spinal cord injury is a critical part of the initial
          on-field assessment.
        </li>
        <li>
          • Do not remove a helmet or any other equipment unless trained to do
          so safely.
        </li>
      </ul>
    </React.Fragment>
  );
};

export default SCAT5Info;

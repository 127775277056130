import { connect } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import {
  toggleSegmentRecordSelection,
  clearSegmentRecordSelection,
} from "../segment-record-actions";
import { getSelectedSegmentRecords } from "../../../selectors";
import CommonList from "../../common/list/common-list";
import CommonDateTimeDisplayField from "../../common/display-field/date-time/date-time";

import "./segment-record-list.scss";
import CommonButton from "../../common/button/common-button.tsx";

class SegmentRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEnabled: false,
      selectedSegmentRecordIds: [],
      sortedSegmentRecords: [],
      sorted: false,
    };

    this.toggleCompare = this.toggleCompare.bind(this);
    this.cancelSelect = this.cancelSelect.bind(this);
    this.compare = this.compare.bind(this);
    this.clearSelect = this.clearSelect.bind(this);
  }

  cancelSelect() {
    this.setState({ selectEnabled: false });
  }

  clearSelect() {
    this.props.clearSegmentRecordSelection();
  }

  toggleCompare() {
    this.setState({ selectEnabled: !this.state.selectEnabled });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.selectedSegmentRecords != this.props.selectedSegmentRecords) {
      if (this.props.selectedSegmentRecords) {
        this.setState({
          selectedSegmentRecordIds: this.props.selectedSegmentRecords.map(
            (segmentRecord) => segmentRecord.id
          ),
        });
      } else {
        this.setState({ selectedSegmentRecordIds: [] });
      }
    }

    if (
      oldProps.segmentRecords != this.props.segmentRecords &&
      this.props.segmentRecords.length > 0
    ) {
      if (
        !this.state.sorted ||
        oldProps.segmentRecords.length != this.props.segmentRecords.length
      ) {
        // console.log("sorting", this.props.segmentRecords.length);
        const sortedSegmentRecords = this.props.segmentRecords
          .slice()
          .sort((a, b) => {
            return (
              new Date(b.segmentRecordStartTime).getTime() -
              new Date(a.segmentRecordStartTime).getTime()
            );
          });
        this.setState({ sortedSegmentRecords, sorted: true });
      }
    }
  }

  compare() {
    if (
      this.state.selectedSegmentRecordIds.length >= 1 &&
      this.props.baselineId
    ) {
      const query =
        "baseline_id=" +
        this.props.baselineId +
        "&segment_record_ids=" +
        JSON.stringify(this.state.selectedSegmentRecordIds);
      this.props.history.push(`/compare?${query}`);
    } else window.alert("Must a baseline and 1 or more workouts to compare");
  }

  render() {
    const { segmentRecords } = this.props;
    //sort by created date
    const sortedSegmentRecords = this.state.sortedSegmentRecords;

    const dataDisplayDefinitions = [
      { key: "segmentRecordStartTime", display: "Dates" },
      { key: "applet_name", display: "Applet", style: { textAlign: "center" } },
    ];
    if (this.state.selectEnabled) {
      dataDisplayDefinitions.unshift({ key: "checkbox" });
    }
    let segmentRecordList;
    if (segmentRecords) {
      const entities = sortedSegmentRecords.map((segmentRecord) => {
        let formattedSegmentRecord = { ...segmentRecord };
        formattedSegmentRecord.segmentRecordStartTime = (
          <Link to={`/segment-record/${segmentRecord.id}`}>
            <CommonDateTimeDisplayField
              dateTime={segmentRecord.segmentRecordStartTime}
            />
          </Link>
        );

        const baseline = segmentRecord.id == this.props.baselineId;
        formattedSegmentRecord.highlighted = baseline;
        const selected =
          this.state.selectedSegmentRecordIds.includes(segmentRecord.id) ||
          baseline;
        if (this.state.selectEnabled) {
          formattedSegmentRecord.checkbox = (
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={() =>
                  this.props.toggleSegmentRecordSelection(segmentRecord.id)
                }
                checked={selected}
              />
            </label>
          );
        }

        return formattedSegmentRecord;
      });
      segmentRecordList = (
        <CommonList
          entities={entities}
          dataDisplayDefinitions={dataDisplayDefinitions}
        />
      );
    }
    let compareButton = (
      <CommonButton modifiers={["red"]} onClick={this.toggleCompare}>
        Select Workouts
      </CommonButton>
    );
    if (this.state.selectEnabled) {
      compareButton = (
        <React.Fragment>
          <div className="compare-selection-buttons__cancel-button__container">
            <CommonButton onClick={this.cancelSelect}>Cancel</CommonButton>
          </div>
          <div className="compare-selection-buttons__clear-button__container">
            <CommonButton onClick={this.clearSelect}>
              Clear Selection
            </CommonButton>
          </div>
          <div className="compare-selection-buttons__compare-button__container">
            <CommonButton modifiers={["red"]} onClick={this.compare}>
              Compare
            </CommonButton>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="compare-selection-buttons__container">
          {compareButton}
        </div>
        {segmentRecordList}
      </React.Fragment>
    );
  }
}

export { SegmentRecordList };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state) => {
  const selectedSegmentRecords = getSelectedSegmentRecords(state);
  const baselineId = state.segmentRecordReducer.baselineId;
  return { selectedSegmentRecords, baselineId };
};

export default connect(mapStateToProps, {
  toggleSegmentRecordSelection,
  clearSegmentRecordSelection,
})(SegmentRecordList);

import React from "react";
import CommonListItem from "./item/common-list-item";
import CommonListHeader from "./header/common-list-header";

import "./common-list.scss";

class CommonList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { entities, dataDisplayDefinitions } = this.props;
    let entityItemList;
    if (entities) {
      const entityIds = Object.keys(entities);
      entityItemList = entityIds.map((entityId) => {
        const key = this.props.sortBy
          ? entities[entityId][this.props.sortBy]
          : entities[entityId].id || entityId;
        return (
          <CommonListItem
            key={key}
            entity={entities[entityId]}
            dataDisplayDefinitions={dataDisplayDefinitions}
          />
        );
      });
    }

    if (entityItemList && this.props.sortBy) {
      const collator = new Intl.Collator("en");
      entityItemList.sort((entityA, entityB) =>
        collator.compare(entityA.key, entityB.key)
      );
    }

    return (
      <React.Fragment>
        <div className="data-table__container">
          <table className="data-table">
            <CommonListHeader dataDisplayDefinitions={dataDisplayDefinitions} />
            <tbody>{entityItemList}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default CommonList;

import { insertOrUpdateMultiple } from "../../easy-reducer.js";

export const sessionActionTypes = {
  start: "START_SESSION",
  getCurrent: "GET_CURRENT_SESSION",
  endCurrent: "END_CURRENT_SESSION",
  getSessionById: "GET_SESSION_BY_ID",
  getSessionByIdList: "GET_SESSION_BY_ID_LIST",
};

export const stateShape = { entities: {} };

const sessionReducer = (state = stateShape, action) => {
  switch (action.type) {
    case "NORM_DATA_RECEIVED": {
      let newState = null;

      const { sessions } = action;

      // check for new sessions
      if (sessions) {
        newState = insertOrUpdateMultiple(state, sessions);
      }
      if (newState === null) return state;
      return newState;
    }

    default:
      return state;
  }
};
export default sessionReducer;

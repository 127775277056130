import React from "react";

import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";
import FormRadioSelectGridContainer from "../../form/radio-select/grid-container/form-radio-select-grid-container";
import SCAT5InputRadio from "../input/radio/scat5-input-radio";
import FormRows from "../../form/rows/form-rows";
import SCAT5InputCommon from "../input/common/scat5-input-common";
import { useFormContextValue } from "../form-context";

const NeurologicalScreen = (): JSX.Element => {
  const balanceScores = [
    Number(useFormContextValue("doubleLegStanceErrors")),
    Number(useFormContextValue("singleLegStanceErrors")),
    Number(useFormContextValue("tandemStanceErrors")),
  ];

  const totalScore = balanceScores.reduce((a, b) => a + b);
  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textTransform: "uppercase", textAlign: "center" }}>
        STEP 4: NEUROLOGICAL SCREEN
      </h2>
      <p>
        See the SCAT5 instructions for details of test administration and
        scoring of the tests. The instructions can be viewed at any time by
        pressing the &ldquo;SCAT5 Instructions&rdquo; button
      </p>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Can the patient read aloud (e.g. symptom checklist)
          and follow instructions without difficulty?"
          name="canReadAndFollowInstructions"
          options={{ Y: true, N: false }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Does the patient have a full range of painfree
          PASSIVE cervical spine movement?"
          name="fullRangePainFreePassiveCervialSpineMotion"
          options={{ Y: true, N: false }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Without moving their head or neck, can the patient look
          side-to-side and up-and-down without double vision?"
          name="canLookSideToSideUpAndDown"
          options={{ Y: true, N: false }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Can the patient perform the finger nose
          coordination test normally?"
          name="canPerformFingerNoseCoordination"
          options={{ Y: true, N: false }}
        />
        <SCAT5InputRadio
          modifiers={["horizontal"]}
          label="Can the patient perform tandem gait normally?"
          name="canPerformTandemGait"
          options={{ Y: true, N: false }}
        />
      </FormRadioSelectGridContainer>
      <h2>BALANCE EXAMINATION</h2>
      <h3>
        Modified Balance Error Scoring System (mBESS) testing<sup>5</sup>
      </h3>
      <FormRadioSelectGridContainer>
        <SCAT5InputRadio
          label="Which foot was tested (i.e. which is the non-dominant foot)"
          name="footTested"
          options={{ Left: "left", Right: "right" }}
        />
      </FormRadioSelectGridContainer>
      <FormRows>
        <SCAT5InputCommon
          label="Testing surface (hard floor, field, etc.)"
          id="testing-surface"
          name="testingSurface"
          type="text"
        />
        <SCAT5InputCommon
          label="Footwear (shoes, barefoot, braces, tape, etc.)"
          id="footware"
          name="footware"
          type="text"
        />
        <h3 style={{ gridColumn: "1/2", justifySelf: "center" }}>Condition</h3>
        <h3 style={{ gridColumn: "2/3", justifySelf: "center" }}>Errors</h3>
        <SCAT5InputCommon
          label="Double leg stance"
          id="double-leg-stance-errors"
          name="doubleLegStanceErrors"
          type="number"
        />
        <div style={{ gridColumn: "3/3" }}> of 10</div>
        <SCAT5InputCommon
          label="Single leg stance (non-dominant foot)"
          id="single-leg-stance-errors"
          name="singleLegStanceErrors"
          type="number"
        />
        <div style={{ gridColumn: "3/3" }}> of 10</div>
        <SCAT5InputCommon
          label="Tandem stance (non-dominant foot at the back)"
          id="tandem-stance-errors"
          name="tandemStanceErrors"
          type="number"
        />
        <div style={{ gridColumn: "3/3" }}> of 10</div>
        <div style={{ gridColumn: "1/2" }}>TOTAL ERRORS</div>
        <div style={{ gridColumn: "2/3" }}>{totalScore}</div>
        <div style={{ gridColumn: "3/3" }}> of 30</div>
      </FormRows>
    </React.Fragment>
  );
};

export default NeurologicalScreen;

import React from "react";
import { connect } from "react-redux";
import SegmentRecordResultsGraph from "../../results-graph";
import {
  getRecordHRLineData,
  getRecordLineData,
  processJumpRecordSegments,
  processResistanceRecordSegments,
} from "../../graph_utilities";

class SegmentRecordBaselineRecordGraphs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterRate: 12,
      graphs: {
        "BL-WARM-UP-CARDIO": {
          lines: {},
          xAxis: [],
          chartLabel: "Warm-up Cardio",
          type: "JUMP",
        },
        "BL-WARM-UP-RESISTANCE": {
          lines: {},
          xAxis: [],
          chartLabel: "Warm-up Resistance",
          type: "RESISTANCE",
        },
        "BL-TORSO-ROTATION": {
          lines: {},
          xAxis: [],
          chartLabel: "Torso Rotation",
          type: "RESISTANCE",
        },
        "BL-SINGLE-JUMP": {
          lines: {},
          xAxis: [],
          chartLabel: "Single Leg Jump",
          type: "JUMP",
        },
        "BL-CHEST-FLY": {
          lines: {},
          xAxis: [],
          chartLabel: "Chest Fly",
          type: "RESISTANCE",
        },
        "BL-WARM-DOWN": {
          lines: {},
          xAxis: [],
          chartLabel: "Warm-down",
          type: "JUMP",
        },
      },
    };
  }

  componentDidMount() {
    const segmentRecord = this.props.segmentRecord;
    // console.log("segment record", segmentRecord);

    const heartBPMSamples = segmentRecord.heartBPMSamples;

    let newGraphs = this.state.graphs;
    Object.keys(this.state.graphs).forEach((graphName) => {
      const graph = this.state.graphs[graphName];
      let lines = {};
      let xAxis,
        resistanceSegments,
        filteredSamples,
        jumpSamplesInRange = [];
      if (graph.type == "JUMP") {
        ({ jumpSamplesInRange, xAxis } = processJumpRecordSegments({
          segmentRecord,
          segType: graphName,
          filterRate: this.state.filterRate,
        }));

        lines = {
          jumps: {
            data: getRecordLineData(jumpSamplesInRange, "validJumps"),
            color: "#6FF384",
            duration: 2000,
            label: "Score",
          },
          heartBPM: {
            data: getRecordHRLineData(heartBPMSamples),
            color: "#CC2401",
            duration: 4000,
            label: "Heart Rate",
          },
        };
      } else if (graph.type == "RESISTANCE") {
        ({ filteredSamples, resistanceSegments, xAxis } =
          processResistanceRecordSegments({
            segmentRecord,
            segType: graphName,
            filterRate: this.state.filterRate,
          }));
        const initialLines = {
          lowerBandForce: {
            data: getRecordLineData(filteredSamples, "lowerBandForce", false),
            color: "#796FF3",
            duration: 2000,
            lable: "Lower Band Force",
          },
          centerBandForce: {
            data: getRecordLineData(filteredSamples, "centerBandForce", false),
            color: "#796FF3",
            duration: 2000,
            lable: "Center Band Force",
          },
          highBandForce: {
            data: getRecordLineData(filteredSamples, "highBandForce", false),
            color: "#796FF3",
            duration: 2000,
            lable: "High Band Force",
          },
          topBandForce: {
            data: getRecordLineData(filteredSamples, "topBandForce", false),
            color: "#796FF3",
            duration: 2000,
            lable: "Top Band Force",
          },
        };

        lines = {
          heartBPM: {
            data: getRecordHRLineData(heartBPMSamples),
            color: "#CC2401",
            duration: 4000,
            label: "Heart Rate",
          },
        };
        resistanceSegments.forEach((segment) => {
          lines[segment.segChangingBand] =
            initialLines[segment.segChangingBand];
        });
      }

      newGraphs[graphName].lines = lines;
      newGraphs[graphName].xAxis = xAxis;
    });
    this.setState({ graphs: newGraphs });
  }

  render() {
    let page = [];
    const graphs = this.state.graphs;
    Object.keys(graphs).map((graphName) => {
      const graph = graphs[graphName];
      const lines = graph.lines;
      const xAxis = graph.xAxis;
      if (xAxis.length > 0) {
        page.push(
          <div key={`${graphName}--container`}>
            <SegmentRecordResultsGraph
              key={`${graphName}--graph`}
              lines={lines}
              chartLabel={graph.chartLabel}
              xAxis={xAxis}
              baselineSegmentRecord={{}}
              scale="mseconds"
              zoomable={graph.type == "RESISTANCE" ? true : false}
              history={this.props.history}
            />
          </div>
        );
      }
    });
    return page;
  }
}

const mapStateToProps = (state, props) => {
  const segmentRecord = state.segmentRecordReducer.entities[props.sessionId];
  return {
    segmentRecord,
  };
};

export default connect(mapStateToProps, {})(SegmentRecordBaselineRecordGraphs);

import React, { useState, useEffect, useRef } from "react";
import { MarsdenBallExercise } from "./marsden-ball-exercise";

interface MarsdenExercisePageProps {
  startMarsdenSegment: () => void;
  endMarsdenSegment: () => void;
  startSegmentRecord: (string) => void;
  sessionId: string;
  marsdenRadius: number;
  marsdenFontSize: number;
  marsdenNumbersPerRow: number;
}

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const MarsdenExercisePage = ({
  sessionId,
  marsdenRadius,
  marsdenFontSize,
  marsdenNumbersPerRow,
}: MarsdenExercisePageProps): JSX.Element => {
  const [count, setCount] = useState(3);
  const [started, setStarted] = useState(false);

  //   useEffect(() => {
  //     startSegmentRecord(sessionId);
  //   }, [startSegmentRecord, sessionId]);

  useInterval(
    () => {
      setCount(count - 1);
      if (count == 1) {
        setStarted(true);
        MarsdenBallExercise({
          width: 800,
          height: 600,
          radius: marsdenRadius,
          fontSize: marsdenFontSize,
          numbersPerRow: marsdenNumbersPerRow,
          parentId: "marsden-ball-exercise-container",
          seed: sessionId,
        });
      }
    },
    count > 0 ? 1000 : null
  );

  console.log("hi");

  return (
    <div className="page-wrapper">
      <div
        id="marsden-ball-exercise-container"
        style={{ width: "fit-content", margin: "auto" }}
      ></div>
    </div>
  );
};

export default MarsdenExercisePage;

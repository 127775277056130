import { connect } from "react-redux";
import React from "react";
import { getAllAthletesForTrainer } from "../modules/athlete/athlete-actions";
import { getSelectedOrganizationAthletes } from "../selectors";
import RosterList from "../modules/roster/list/roster-list";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";
import AppBar from "../modules/common/app-bar/app-bar";
import OrganizationPicker from "../modules/organization/picker/organization-picker";
import MachinePicker from "../modules/machine/picker/machine-picker";

class RosterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.trainerId) {
      this.props.getAllAthletesForTrainer(this.props.trainerId);
    }
  }

  render() {
    return (
      <div className="page-wrapper">
        <AppBar pageName="My Roster" />
        <div style={{ display: "block" }}>
          <OrganizationPicker />
          <MachinePicker />
        </div>
        <RosterList
          athletes={this.props.athletes}
          history={this.props.history}
        />
        <br />
        <br />
        <br />
        <UserFeedbackDrawer />
      </div>
    );
  }
}

export { RosterPage };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state) => {
  let athletes = getSelectedOrganizationAthletes(state);
  let trainerId = state.accountReducer.currentUserId;
  let name = state.accountReducer.currentUserName;
  return { athletes, trainerId, name };
};

export default connect(mapStateToProps, { getAllAthletesForTrainer })(
  RosterPage
);

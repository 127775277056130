import { connect } from "react-redux";
import React from "react";
import { getAthlete } from "../selectors";
import { getAthleteById } from "../modules/athlete/athlete-actions";
import AppBar from "../modules/common/app-bar/app-bar";
import CommonCenterBanner from "../modules/common/banner/center/common-center-banner";
import AthleteEditForm from "../modules/athlete/form/edit-form/athlete-edit-form";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";
import OrganizationPicker from "../modules/organization/picker/organization-picker";

class TrainerProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.user) {
      this.props.getAthleteById(this.props.userId);
      // console.log("requesting ath");
    }
  }

  render() {
    // console.log("USER", this.props.userId);
    let user = this.props.user;
    if (!user) {
      user = { name: "" };
    }
    return (
      <div className="page-wrapper">
        <AppBar pageName="User Profile" />
        <CommonCenterBanner>
          <div>User:</div>
          <div>{user.name}</div>
          <div style={{ marginTop: "20px" }}>
            <OrganizationPicker />
          </div>
        </CommonCenterBanner>
        <AthleteEditForm
          enablePasswordField={true}
          organizationId={this.props.organizationId}
          athlete={user}
          onSuccessRedirectTo="/"
          onCancel={() => this.props.history.push("/")}
        />
        <UserFeedbackDrawer />
      </div>
    );
  }
}

export { TrainerProfilePage };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state) => {
  const organizationId = state.accountReducer.selectedOrganization;
  const userId = state.accountReducer.currentUserId;
  const user = getAthlete(state, userId);
  // console.log("User", user);
  return { userId, user, organizationId };
};

export default connect(mapStateToProps, { getAthleteById })(TrainerProfilePage);

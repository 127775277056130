import { schema } from "normalizr";

const athleteSchema = new schema.Entity("athletes");
const sessionSchema = new schema.Entity("sessions");
const segmentRecordSchema = new schema.Entity("segmentRecord");
const userSchema = new schema.Entity("users");
const machineSchema = new schema.Entity("machines");

athleteSchema.define({
  sessions: [sessionSchema],
});

sessionSchema.define({
  user: athleteSchema,
  trainer_id: userSchema,
  machine_id: machineSchema,
  segment_records: [segmentRecordSchema],
});

segmentRecordSchema.define({
  session_id: sessionSchema,
});

userSchema.define({
  sessions: [sessionSchema],
});

machineSchema.define({
  current_session_id: sessionSchema,
});

export { athleteSchema, sessionSchema, machineSchema, segmentRecordSchema };

import { connect } from "react-redux";
import { editAthlete } from "../../athlete-actions";
import AthleteForm from "../athlete-form";
import React from "react";

class AthleteEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      athlete: {},
    };
    this.submitForm = this.submitForm.bind(this);
    this.getUserCode = this.getUserCode.bind(this);
  }

  componentDidMount() {
    let athlete = { ...this.props.athlete };

    athlete.user_code = this.getUserCode(athlete);
    this.setState({ athlete });
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.athlete != this.props.athlete ||
      oldProps.organizationId != this.props.organizationId
    ) {
      let athlete = { ...this.props.athlete };
      athlete.user_code = this.getUserCode(athlete);
      this.setState({ athlete });
    }
  }

  getUserCode(athlete) {
    // console.log("athlete", athlete);
    if (athlete.organizations) {
      const selectedOrganizationPosistion = athlete.organizations.findIndex(
        (org) =>
          org.id == this.props.organizationId && org.pivot.role == "athlete"
      );
      if (athlete.organizations[selectedOrganizationPosistion]) {
        return athlete.organizations[selectedOrganizationPosistion].pivot
          .user_code;
      }
    }
    return undefined;
  }

  submitForm(athleteData) {
    return (e) => {
      e.preventDefault();
      this.props.editAthlete(
        "editAthlete-" + this.props.athlete.id,
        this.state.athlete.id,
        this.props.organizationId,
        athleteData
      );
    };
  }

  render() {
    const {
      name = "",
      dob = "",
      weight = "",
      height = "",
      email = "",
      password = "",
      passwordConfirm = "",
      user_code = "",
    } = this.state.athlete;
    let athleteData = {
      name,
      dob,
      weight,
      height,
      email,
      password,
      passwordConfirm,
      userCode: user_code,
    };
    const formSubmissionId = "editAthlete-" + this.state.athlete.id;
    if (this.props.registrationState[formSubmissionId]) {
      athleteData =
        this.props.registrationState[formSubmissionId].submittedData;
    }
    return (
      <AthleteForm
        {...athleteData}
        enablePasswordField={this.props.enablePasswordField}
        formSubmissionState={this.props.registrationState[formSubmissionId]}
        formSubmissionId={formSubmissionId}
        onSuccessRedirectTo={this.props.onSuccessRedirectTo}
        onCancel={this.props.onCancel}
        submitForm={this.submitForm}
      />
    );
  }
}
const mapStateToProps = (state) => {
  let registrationState = state.athleteReducer.formSubmissionState;
  return { registrationState };
};

export default connect(mapStateToProps, { editAthlete })(AthleteEditForm);

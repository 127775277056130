import { takeEvery, put, all } from "redux-saga/effects";
import { sessionActionTypes } from "./session-reducer.js";
import { sessionReceived } from "./session-actions.js";
import { setCurrentSession } from "../machine/machine-actions.js";
import { athleteReceived } from "../athlete/athlete-actions.js";
import request from "../../my-axios.js";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  start: (machineId, athleteId) => `api/v1/${machineId}/${athleteId}/session`,
  getCurrent: (machineId) => `api/v1/${machineId}/current-session`,
  endCurrent: (machineId) => `api/v1/${machineId}/end-current-session`,
  getSessionById: (sessionId) => `api/v1/session/${sessionId}`,
  getSessionByIdList: () => `api/v1/session-list`,
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* sessionSagas() {
  yield all([
    takeEvery(sessionActionTypes.start, startSessionSaga),
    takeEvery(sessionActionTypes.getCurrent, getCurrentSessionSaga),
    takeEvery(sessionActionTypes.endCurrent, endCurrentSessionSaga),
    takeEvery(sessionActionTypes.getSessionById, getSessionByIdSaga),
    takeEvery(sessionActionTypes.getSessionByIdList, getSessionByIdListSaga),
  ]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* startSessionSaga(action) {
  try {
    var data = { data: action.data, trainerId: action.trainerId };
    const req = request.post(
      ENDPOINTS.start(action.machineId, action.athleteId),
      data
    );
    const response = yield req;
    yield put(sessionReceived(response.data.session));
    yield put(setCurrentSession(action.machineId, response.data.session.id));
  } catch (exception) {
    window.testError = exception;
    if (exception.status == 422) {
      // bad form data
      const response = JSON.parse(exception.response.text);
      console.error(response);
    }
  }
}

function* getCurrentSessionSaga(action) {
  const req = request.get(ENDPOINTS.getCurrent(action.machineId));
  const response = yield req;
  // console.log("response", response);
  if (response.data.athlete != null && response.data.session != null) {
    yield put(sessionReceived(response.data.session));
    yield put(athleteReceived(response.data.athlete));
    yield put(setCurrentSession(action.machineId, response.data.session.id));
  }
}

function* endCurrentSessionSaga(action) {
  const req = request.get(ENDPOINTS.endCurrent(action.machineId));
  const response = yield req;
  // console.log("response!", response);
  if (response.data.message == "success") {
    yield put(setCurrentSession(action.machineId, null));
  }
}

function* getSessionByIdSaga(action) {
  try {
    const req = request.get(ENDPOINTS.getSessionById(action.sessionId));
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(sessionReceived(response.data.session));
  } catch (exception) {
    window.testError = exception;
  }
}

function* getSessionByIdListSaga(action) {
  try {
    const req = request.post(ENDPOINTS.getSessionByIdList(), {
      sessionIds: action.sessionIds,
    });
    const response = yield req;
    // console.log("RESPNOSE", response);
    yield put(sessionReceived(response.data.sessions));
  } catch (exception) {
    window.testError = exception;
  }
}

export const sagas = { sessionSagas };

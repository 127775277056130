import { insertOrUpdateInto } from "../../easy-reducer";
export const accountActionTypes = {
  login: "LOG_IN",
  accountSignedIn: "USER_SIGNED_IN",
  checkLogin: "CHECK_LOGIN",
  logOut: "LOG_OUT",
  loggedOut: "LOGGED_OUT",
  selectOrganization: "SELECT_ORGANIZATION",
  setLoginAttemptStatusMessage: "SET_LOGIN_ATTEMPT_STATUS_MESSAGE",
  setLoginAttemptStatusType: "SET_LOGIN_ATTEMPT_STATUS_TYPE",
};

export const stateShape = {
  signedIn: false,
  currentUserId: null,
  organizations: {},
  selectedOrganization: null,
  loginAttemptStatusMessage: "",
  loginAttemptStatusType: "",
};

const accountReducer = (state = stateShape, action) => {
  switch (action.type) {
    case "USER_SIGNED_IN": {
      const newState = {
        ...state,
        signedIn: true,
        currentUserId: action.user.id,
        currentUserName: action.user.name,
        selectedOrganization: action.user.organizations[0].id,
      };

      let newOrgs = state.organizations;
      action.user.organizations.forEach((organization) => {
        newOrgs = insertOrUpdateInto(newOrgs, organization);
      });
      newState.organizations = newOrgs;
      return newState;
    }

    case accountActionTypes.setLoginAttemptStatusMessage: {
      let newState = { ...state };
      newState.loginAttemptStatusMessage = action.message;
      return newState;
    }

    case accountActionTypes.setLoginAttemptStatusType: {
      let newState = { ...state };
      newState.loginAttemptStatusType = action.statusType;
      return newState;
    }

    case accountActionTypes.selectOrganization: {
      let newState = { ...state };
      newState.selectedOrganization = action.organizationId;
      return newState;
    }

    case "LOGGED_OUT":
      return { ...stateShape };

    default:
      return state;
  }
};

export default accountReducer;

import React, { useContext } from "react";
import { FormContext, useFormContextValue } from "../../form-context";

import SCAT5InputRadio from "../../input/radio/scat5-input-radio";
import CommonButton from "../../../common/button/common-button";
import SCAT5InputCommon from "../../input/common/scat5-input-common";
import FormRows from "../../../form/rows/form-rows";
import ScoreBox from "../../../form/score-box/score-box";

const SACImmediateMemory = (): JSX.Element => {
  const { setValue } = useContext(FormContext);
  const selectedSACWordCount = Number(
    useFormContextValue("selectedSACWordCount")
  );

  const scores = [
    Number(useFormContextValue("trial1")),
    Number(useFormContextValue("trial2")),
    Number(useFormContextValue("trial3")),
  ];

  const sum = scores.reduce((a, b) => a + b);

  let selectedWordListOptions = null;
  switch (selectedSACWordCount) {
    case 5:
      selectedWordListOptions = {
        "A: Finger, Penny, Blanket, Lemon, Insect": "a",
        "B: Candle, Paper, Sugar, Sandwich, Wagon": "b",
        "C: Baby, Monkey, Perfume, Sunset, Iron": "c",
        "D: Elbow, Apple, Carpet, Saddle, Bubble": "d",
        "E: Jacket, Arrow, Pepper, Cotton, Movie": "e",
        "F: Dollar, Honey, Mirror, Saddle, Anchor": "f",
      };
      break;
    case 10:
      selectedWordListOptions = {
        "G: Finger, Candle, Penny, Paper, Blanket, Sugar, Lemon, Sandwich, Insect, Wagon":
          "g",
        "H: Baby, Elbow, Monkey, Apple, Perfume, Carpet, Sunset, Saddle, Iron, Bubble":
          "h",
        "I: Jacket, Dollar, Arrow, Honey, Pepper, Mirror, Cotton, Saddle, Movie, Anchor":
          "i",
      };
      break;
  }

  return (
    <React.Fragment>
      <CommonButton
        onClick={() => {
          setValue("selectedSACWordCount", 5);
        }}
      >
        5 Word List
      </CommonButton>
      <CommonButton
        onClick={() => {
          setValue("selectedSACWordCount", 10);
        }}
      >
        10 Word List
      </CommonButton>

      {selectedWordListOptions ? (
        <React.Fragment>
          <SCAT5InputRadio
            label={`Alternate ${selectedSACWordCount} word lists`}
            name={`selectedSACWordList`}
            options={selectedWordListOptions}
          />
          <FormRows>
            <SCAT5InputCommon
              label="Trial 1"
              id="trial-1"
              name="trial1"
              type="number"
            />
            <SCAT5InputCommon
              label="Trial 2"
              id="trial-2"
              name="trial2"
              type="number"
            />
            <SCAT5InputCommon
              label="Trial 3"
              id="trial-3"
              name="trial3"
              type="number"
            />
            <SCAT5InputCommon
              label="Time that last trial was completed"
              id="completion-time"
              name="completionTime"
              type="time"
            />
          </FormRows>
          <ScoreBox label="Immediate Memory Score">{`${sum} of ${
            selectedSACWordCount * 3
          }`}</ScoreBox>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default SACImmediateMemory;

import { takeEvery, put, all } from "redux-saga/effects";
import { userFeedbackActionTypes } from "./user-feedback-reducer";
import {
  feedbackSent,
  feedbackReceived,
  feedbackError,
} from "./user-feedback-actions";
import request from "../../my-axios.js";

//= ################################################
//				ENDPOINTS
//= ################################################

const ENDPOINTS = {
  postUserFeedback: () => "api/v1/user-feedback",
};

//= ################################################
//				WATCHER FUNCTIONS
//= ################################################

function* userFeedbackSagas() {
  yield all([
    takeEvery(userFeedbackActionTypes.postUserFeedback, postUserFeedbackSaga),
  ]);
}

//= ################################################
//				WORKER FUNCTIONS
//= ################################################

function* postUserFeedbackSaga(action) {
  try {
    const data = { userId: action.userId, userFeedback: action.userFeedback };
    const req = request.post(ENDPOINTS.postUserFeedback(), data);
    yield put(feedbackSent());
    const response = yield req;
    if (response.data.success == true) {
      yield put(feedbackReceived());
    } else {
      yield put(feedbackError());
    }
    // console.log("FEEDBACK RESPONSE", response);
  } catch (exception) {
    console.error(exception);
    yield put(feedbackError());
  }
}

export const sagas = { userFeedbackSagas };

import React from "react";
import { connect } from "react-redux";
import { getAthletes } from "../../../selectors";
import CommonList from "../../common/list/common-list";
import CommonDateTimeDisplayField from "../../common/display-field/date-time/date-time";

class CardioComparisonList extends React.Component {
  render() {
    const baseline = this.props.baselineSegmentRecord;
    const baselineResult = {
      ...this.props.baselineResultData,
      date: (
        <CommonDateTimeDisplayField
          dateTime={baseline.segmentRecordStartTime}
        />
      ),
      athlete: this.props.baselineAthlete.name,
    };
    baselineResult.highlighted = true;
    const comparisons = this.props.comparisonSegmentRecords;
    const results = comparisons.map((comparison) => {
      let results = { ...comparison.results };
      results.date = (
        <CommonDateTimeDisplayField
          dateTime={comparison.segmentRecord.segmentRecordStartTime}
        />
      );
      results.athlete = comparison.athlete.name;
      return results;
    });
    results.unshift(baselineResult);
    let dataDisplayDefinitions = [
      { key: "date", display: "Date" },
      {
        key: "elapsedTargetedCardioTime",
        display: "Time",
        style: { textAlign: "center" },
      },
      { key: "jumps", display: "Jumps", style: { textAlign: "center" } },
      {
        key: "jumpPercentage",
        display: "Jump %",
        style: { textAlign: "center" },
      },
      { key: "calories", display: "Calories", style: { textAlign: "center" } },
      { key: "maxCardioHR", display: "Max HR", style: { textAlign: "center" } },
      {
        key: "averageCardioHR",
        display: "Average HR",
        style: { textAlign: "center" },
      },
      { key: "minCardioHR", display: "Min HR", style: { textAlign: "center" } },
    ];
    if (this.props.multipleAthletes) {
      dataDisplayDefinitions.unshift({
        key: "athlete",
        display: "Athlete",
        style: { textAlign: "center" },
      });
    }
    return (
      <CommonList
        entities={results}
        dataDisplayDefinitions={dataDisplayDefinitions}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const athletes = getAthletes(state);
  return { athletes };
};

export default connect(mapStateToProps, null)(CardioComparisonList);

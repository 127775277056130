import React from "react";

import { useFormContextValue } from "../form-context";

import ScrollToHereOnMount from "../../common/scroll-to-here-on-mount/scroll-to-here-on-mount";
import StandardSubForm from "../standard-subform/standard-subform";
import SACOrientation from "./subforms/sac-orientation";
import SACImmediateMemory from "./subforms/sac-immediate-memory";
import SACDigitsBackwards from "./subforms/sac-digits-backwards";
import SACMonthsInReverse from "./subforms/sac-months-in-reverse";
import ScoreBox from "../../form/score-box/score-box";

const CognitiveScreening = (): JSX.Element => {
  const digitsBackwards = useFormContextValue("sacDigitsBackwards");
  const monthsBackwards = useFormContextValue("sacMonthsInReverse");
  const digitStrings = digitsBackwards
    ? {
        string0: digitsBackwards["numberString0"] == "true",
        string1: digitsBackwards["numberString1"] == "true",
        string2: digitsBackwards["numberString2"] == "true",
        string3: digitsBackwards["numberString3"] == "true",
        string4: digitsBackwards["numberString4"] == "true",
        string5: digitsBackwards["numberString5"] == "true",
        string6: digitsBackwards["numberString6"] == "true",
        string7: digitsBackwards["numberString7"] == "true",
      }
    : {};

  const digitScores = [
    digitStrings["string0"] && digitStrings["string1"] ? 1 : 0,
    digitStrings["string2"] && digitStrings["string3"] ? 1 : 0,
    digitStrings["string4"] && digitStrings["string5"] ? 1 : 0,
    digitStrings["string6"] && digitStrings["string7"] ? 1 : 0,
  ];

  const digitScore = digitScores.reduce((a, b) => a + b);
  const monthScore = monthsBackwards
    ? monthsBackwards["canListMonthsInReverse"] == "true"
      ? 1
      : 0
    : 0;
  const concentrationScore = digitScore + monthScore;

  return (
    <React.Fragment>
      <ScrollToHereOnMount />
      <h2 style={{ textTransform: "uppercase", textAlign: "center" }}>
        STEP 3: COGNITIVE SCREENING
      </h2>
      <h3 style={{ textAlign: "center" }}>
        Standardised Assessment of Concussion (SAC)<sup>4</sup>
      </h3>
      <h2>ORIENTATION</h2>
      <StandardSubForm name="sacOrientation">
        <SACOrientation />
      </StandardSubForm>
      <h2>IMMEDIATE MEMORY</h2>
      <p>
        The Immediate Memory component can be completed using the traditional
        5-word per trial list or optionally using 10-words per trial to minimise
        any ceiling effect. All 3 trials must be administered irrespective of
        the number correct on the first trial. Administer at the rate of one
        word per second.
      </p>
      <p style={{ color: "#CC2401" }}>
        <b>Please choose EITHER the 5 or 10 word list groups.</b>
      </p>
      <p>
        <i>
          I am going to test your memory. I will read you a list of words and
          when I am done, repeat back as many words as you can remember, in any
          order. For Trials 2 & 3: I am going to repeat the same list again.
          Repeat back as many words as you can remember in any order, even if
          you said the word before.
        </i>
      </p>
      <StandardSubForm name="sacImmediateMemory">
        <SACImmediateMemory />
      </StandardSubForm>
      <h2 style={{ textTransform: "uppercase", textAlign: "center" }}>
        CONCENTRATION
      </h2>
      <h2>DIGITS BACKWARDS</h2>
      <p>
        Please click the Digit list chosen (A, B, C, D, E, F). Administer at the
        rate of one digit per second reading DOWN the selected column.
      </p>
      <p>
        <i>
          I am going to read a string of numbers and when I am done, you repeat
          them back to me in reverse order of how I read them to you. For
          example, if I say 7-1-9, you would say 9-1-7.
        </i>
      </p>
      <StandardSubForm name="sacDigitsBackwards">
        <SACDigitsBackwards />
      </StandardSubForm>
      <h2>MONTHS IN REVERSE ORDER</h2>
      <p>
        <i>
          Now tell me the months of the year in reverse order. Start with the
          last month and go backward. So you’ll say December, November. Go
          ahead.
        </i>
      </p>
      <StandardSubForm name="sacMonthsInReverse">
        <SACMonthsInReverse />
      </StandardSubForm>
      <ScoreBox label="Concentration Total Score (Digits + Months)">
        {concentrationScore} of 5
      </ScoreBox>
    </React.Fragment>
  );
};

export default CognitiveScreening;

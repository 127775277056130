import { connect } from "react-redux";
import React from "react";
import SegmentRecordList from "../modules/segment-record/list/segment-record-list";
import { getAthlete, getAthleteSegmentRecords } from "../selectors";
import { getAthleteById } from "../modules/athlete/athlete-actions";
import { getSegmentRecordHistoryByAthleteId } from "../modules/segment-record/segment-record-actions";
import UserFeedbackDrawer from "../modules/user-feedback/drawer/user-feedback-drawer";
import AppBar from "../modules/common/app-bar/app-bar";
import CommonCenterBanner from "../modules/common/banner/center/common-center-banner";
import SideNavigation from "../modules/side-navigation/side-navigation";
import CommonButton from "../modules/common/button/common-button";

class AthleteLogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.concussion = this.concussion.bind(this);
  }

  componentDidMount() {
    if (!this.props.athlete) {
      this.props.getAthleteById(this.props.athleteId);
    }
    this.props.getSegmentRecordHistoryByAthleteId(this.props.athleteId);
  }

  concussion() {
    this.props.history.push("/scat5/" + this.props.athleteId);
  }

  render() {
    let athlete = this.props.athlete;
    if (!athlete) {
      athlete = { name: "", id: "" };
    }
    let segmentRecords = this.props.segmentRecords;
    return (
      <div className="page-wrapper">
        <AppBar pageName="Athlete Log" />
        <CommonCenterBanner>
          <div>{`${athlete.name}'s`}</div>
          <div>Previous Workouts</div>
        </CommonCenterBanner>

        <SegmentRecordList
          segmentRecords={segmentRecords}
          history={this.props.history}
        />
        <div style={{ textAlign: "center" }}>
          <CommonButton modifiers={["red"]} onClick={this.concussion}>
            Perform Concussion Assessment
          </CommonButton>
        </div>
        <SideNavigation
          leftLink="/"
          leftText="back"
          rightLink={`/athlete-profile/${athlete.id}`}
          rightText="Athlete Profile"
        />
        <UserFeedbackDrawer />
      </div>
    );
  }
}

export { AthleteLogPage };

//= =========================================
//		MAP STATE TO PROPS
//= =========================================

const mapStateToProps = (state, props) => {
  const athleteId = props.match.params.athleteId;
  const athlete = getAthlete(state, athleteId);
  //console.log('ATHLETE', athlete);
  const segmentRecords = getAthleteSegmentRecords(state, athleteId);

  //console.log("segmentRecords", segmentRecords);
  return { athleteId, athlete, segmentRecords };
};

export default connect(mapStateToProps, {
  getAthleteById,
  getSegmentRecordHistoryByAthleteId,
})(AthleteLogPage);

import React, { useState } from "react";

import CommonButton from "../../common/button/common-button";
import CommonDrawer from "../../common/drawer/common-drawer";

import "./scat5-instructions-drawer.scss";

const SCAT5InstructionsDrawer = (): JSX.Element => {
  const [displayInstructions, setDisplayInstructions] = useState(false);

  const toggleInstructions = () => {
    setDisplayInstructions(!displayInstructions);
  };
  return (
    <React.Fragment>
      <div className="o-SCAT5InstructionsDrawer__hoverButton">
        <CommonButton onClick={toggleInstructions}>
          SCAT5 Instructions
        </CommonButton>
      </div>
      <CommonDrawer open={displayInstructions} onClose={toggleInstructions}>
        <h1 className="common-drawer__h1">INSTRUCTIONS</h1>
        <h1 className="common-drawer__h1">
          Words in Italics throughout the SCAT5 are the instructions given to
          the athlete by the clinician
        </h1>
        <h1 className="common-drawer__h1">Symptom Scale</h1>
        <p className="common-drawer__p">
          The time frame for symptoms should be based on the type of test being
          administered. At baseline it is advantageous to assess how an athlete
          “typically” feels whereas during the acute/post-acute stage it is best
          to ask how the athlete feels at the time of testing. The symptom scale
          should be completed by the athlete, not by the examiner. In situations
          where the symptom scale is being completed after exercise, it should
          be done in a resting state, generally by approximating his/her resting
          heart rate. For total number of symptoms, maximum possible is 22
          except immediately post injury, if sleep item is omitted, which then
          creates a maximum of 21. For Symptom severity score, add all scores in
          table, maximum possible is 22 x 6 = 132, except immediately post
          injury if sleep item is omitted, which then creates a maximum of
          21x6=126.
        </p>
        <h1 className="common-drawer__h1">Immediate Memory</h1>
        <p className="common-drawer__p">
          The Immediate Memory component can be completed using the traditional
          5-word per trial list or, optionally, using 10-words per trial. The
          literature suggests that the Immediate Memory has a notable ceiling
          effect when a 5-word list is used. In settings where this ceiling is
          prominent, the examiner may wish to make the task more difficult by
          incorporating two 5–word groups for a total of 10 words per trial. In
          this case, the maximum score per trial is 10 with a total trial
          maximum of 30. Choose one of the word lists (either 5 or 10). Then
          perform 3 trials of immediate memory using this list. Complete all 3
          trials regardless of score on previous trials.
        </p>
        <p className="common-drawer__p">
          <i>
            “I am going to test your memory. I will read you a list of words and
            when I am done, repeat back as many words as you can remember, in
            any order.”{" "}
          </i>
          The words must be read at a rate of one word per second.
        </p>
        <p className="common-drawer__p">
          Trials 2 & 3 MUST be completed regardless of score on trial 1 & 2.
        </p>
        <p className="common-drawer__p">Trials 2 & 3:</p>
        <p className="common-drawer__p">
          <i>
            “I am going to repeat the same list again. Repeat back as many words
            as you can remember in any order, even if you said the word before.“
          </i>
        </p>
        <p className="common-drawer__p">
          Score 1 pt. for each correct response. Total score equals sum across
          all 3 trials. Do NOT inform the athlete that delayed recall will be
          tested.
        </p>
        <h1 className="common-drawer__h1">Concentration</h1>
        <h1 className="common-drawer__h1">Digits backward</h1>
        <p className="common-drawer__p">
          Choose one column of digits from lists A, B, C, D, E or F and
          administer those digits as follows:
        </p>
        <p className="common-drawer__p">
          Say:{" "}
          <i>
            “I am going to read a string of numbers and when I am done, you
            repeat them back to me in reverse order of how I read them to you.
            For example, if I say 7-1-9, you would say 9-1-7.”
          </i>
        </p>
        <p className="common-drawer__p">Begin with first 3 digit string.</p>
        <p className="common-drawer__p">
          If correct, select “Y” for correct and go to next string length. If
          incorrect, select “N” for the first string length and read trial 2 in
          the same string length. One point possible for each string length.
          Stop after incorrect on both trials (2 N’s) in a string length. The
          digits should be read at the rate of one per second.
        </p>
        <h1 className="common-drawer__h1">Months in reverse order</h1>
        <p className="common-drawer__p">
          <i>
            “Now tell me the months of the year in reverse order. Start with the
            last month and go backward. So you’ll say December, November ... Go
            ahead”
          </i>
        </p>
        <p className="common-drawer__p">1 pt. for entire sequence correct</p>
        <h1 className="common-drawer__h1">Delayed Recall</h1>
        <p className="common-drawer__p">
          The delayed recall should be performed after 5 minutes have elapsed
          since the end of the Immediate Recall section.
        </p>
        <p className="common-drawer__p">
          <i>
            “Do you remember that list of words I read a few times earlier? Tell
            me as many words from the list as you can remember in any order.“
          </i>
        </p>
        <p className="common-drawer__p">
          Score 1 pt. for each correct response
        </p>
        <h1 className="common-drawer__h1">
          Modified Balance Error Scoring System (mBESS)<sup>5</sup>
          testing
        </h1>
        <p className="common-drawer__p">
          This balance testing is based on a modified version of the Balance
          Error Scoring System (BESS)<sup>5</sup>. A timing device is required
          for this testing.
        </p>
        <p className="common-drawer__p">
          Each of 20-second trial/stance is scored by counting the number of
          errors. The examiner will begin counting errors only after the athlete
          has assumed the proper start position. The modified BESS is calculated
          by adding one error point for each error during the three 20-second
          tests. The maximum number of errors for any single condition is 10. If
          the athlete commits multiple errors simultaneously, only one error is
          recorded but the athlete should quickly return to the testing
          position, and counting should resume once the athlete is set. Athletes
          that are unable to maintain the testing procedure for a minimum of
          five seconds at the start are assigned the highest possible score,
          ten, for that testing condition.
        </p>
        <p className="common-drawer__p">
          OPTION: For further assessment, the same 3 stances can be performed on
          a surface of medium density foam (e.g., approximately 50cm x 40cm x
          6cm).
        </p>
        <h1 className="common-drawer__h1">Balance testing – types of errors</h1>
        <div className="common-drawer__p">
          <ol>
            <li>Hands lifted off iliac crest</li>
            <li>Opening eyes</li>
            <li>Step, stumble, or fall</li>
            <li>Moving hip into &gt; 30 degrees abduction</li>
            <li>Lifting forefoot or heel</li>
            <li>Remaining out of test position &gt; 5 sec</li>
          </ol>
        </div>
        <p className="common-drawer__p">
          <i>
            “I am now going to test your balance. Please take your shoes off (if
            applicable), roll up your pant legs above ankle (if applicable), and
            remove any ankle taping (if applicable). This test will consist of
            three twenty second tests with different stances.“
          </i>
        </p>
        <p className="common-drawer__p">(a) Double leg stance:</p>
        <p className="common-drawer__p">
          <i>
            “The first stance is standing with your feet together with your
            hands on your hips and with your eyes closed. You should try to
            maintain stability in that position for 20 seconds. I will be
            counting the number of times you move out of this position. I will
            start timing when you are set and have closed your eyes.“
          </i>
        </p>
        <p className="common-drawer__p">(b) Single leg stance:</p>
        <p className="common-drawer__p">
          <i>“If you were to kick a ball, which foot would you use? </i>
          [This will be the dominant foot]{" "}
          <i>
            Now stand on your non-dominant foot. The dominant leg should be held
            in approximately 30 degrees of hip flexion and 45 degrees of knee
            flexion. Again, you should try to maintain stability for 20 seconds
            with your hands on your hips and your eyes closed. I will be
            counting the number of times you move out of this position. If you
            stumble out of this position, open your eyes and return to the start
            position and continue balancing. I will start timing when you are
            set and have closed your eyes.“
          </i>
        </p>
        <p className="common-drawer__p">(c) Tandem stance:</p>
        <p className="common-drawer__p">
          <i>
            “Now stand heel-to-toe with your non-dominant foot in back. Your
            weight should be evenly distributed across both feet. Again, you
            should try to maintain stability for 20 seconds with your hands on
            your hips and your eyes closed. I will be counting the number of
            times you move out of this position. If you stumble out of this
            position, open your eyes and return to the start position and
            continue balancing. I will start timing when you are set and have
            closed your eyes.”
          </i>
        </p>
        <h1 className="common-drawer__h1">Tandem Gait</h1>
        <p className="common-drawer__p">
          Participants are instructed to stand with their feet together behind a
          starting line (the test is best done with footwear removed). Then,
          they walk in a forward direction as quickly and as accurately as
          possible along a 38mm wide (sports tape), 3 metre line with an
          alternate foot heel-to-toe gait ensuring that they approximate their
          heel and toe on each step. Once they cross the end of the 3m line,
          they turn 180 degrees and return to the starting point using the same
          gait. Athletes fail the test if they step off the line, have a
          separation between their heel and toe, or if they touch or grab the
          examiner or an object.
        </p>
        <h1 className="common-drawer__h1">Finger to Nose</h1>
        <p className="common-drawer__p">
          “I am going to test your coordination now. Please sit comfortably on
          the chair with your eyes open and your arm (either right or left)
          outstretched (shoulder flexed to 90 degrees and elbow and fingers
          extended), pointing in front of you. When I give a start signal, I
          would like you to perform five successive finger to nose repetitions
          using your index finger to touch the tip of the nose, and then return
          to the starting position, as quickly and as accurately as possible.”
        </p>
        <CommonButton onClick={toggleInstructions}>Close</CommonButton>
      </CommonDrawer>
    </React.Fragment>
  );
};

export default SCAT5InstructionsDrawer;

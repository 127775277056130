import { normalize } from "normalizr";
import { arrayWrap } from "../../easy-reducer.js";
import { machineSchema } from "../../normalizr-schema.js";
import { machineActionTypes } from "./machine-reducer";
import { normalizedDataReceived } from "../app/app-actions.js";

export function machineReceived(machine) {
  const machines = normalize(arrayWrap(machine), [machineSchema]);
  return normalizedDataReceived(machines.entities);
}

export function clearMachines() {
  return { type: "CLEAR_MACHINES" };
}

export function getMachineSessions(machines) {
  return {
    type: machineActionTypes.getMachineSessions,
    machines,
  };
}

export function selectMachine(machineId) {
  return {
    type: machineActionTypes.selectMachine,
    machineId,
  };
}

export function setCurrentSession(machineId, sessionId) {
  return {
    type: machineActionTypes.setCurrentSession,
    machineId,
    sessionId,
  };
}

import React, { useState } from "react";
//import {Form1, Form2} from '../testform/testform'; // But import actual forms

import { FormContext } from "./form-context";
import CommonButton from "../common/button/common-button";

import "./scat5.scss";

interface sliceMapping {
  fromForm: string;
  toForm: string;
  fields: Array<string>;
}

interface SCAT5Props {
  formList: Array<JSX.Element>;
  formNames: Array<string>;
  onSubmit: (segments: any) => void;
  map?: Array<sliceMapping>;
  athlete: any;
  trainer: any;
}

const SCAT5 = ({
  formList,
  formNames,
  onSubmit,
  map,
  athlete,
  trainer,
}: SCAT5Props): JSX.Element => {
  const initialSegments: { [key: string]: any } = {};
  formNames.forEach((name) => {
    initialSegments[name] = {};
  });

  const [segments, setSegments] = useState(initialSegments);
  const [currentStep, setCurrentStep] = useState(0);

  const currentName = formNames[currentStep];

  //only want mappings pertaining to the current form
  let relevantMappings;
  if (map) {
    relevantMappings = map.filter((mapping) => mapping.toForm === currentName);
  }

  const getMappedFormNameForKey = (key: string) => {
    let formName = currentName;
    if (map) {
      // if the key being set is a mapped key, update it at its origin rather than the current form
      const mapping = relevantMappings.find((mapping) =>
        mapping.fields.includes(key)
      );
      formName = mapping ? mapping.fromForm : currentName;
    }
    return formName;
  };

  const setValue = (key: string, value: any) => {
    const formName = getMappedFormNameForKey(key);
    setSegments({
      ...segments,
      [formName]: { ...segments[formName], [key]: value },
    });
    //      console.log('received: ', segment);
    //      console.log('state: ', segments);
  };

  const setValues = (values: Record<string, any>) => {
    const newSegments = { ...segments };
    Object.keys(values).forEach((key) => {
      const formName = getMappedFormNameForKey(key);
      newSegments[formName] = {
        ...newSegments[formName],
        [key]: values[key],
      };
    });
    setSegments(newSegments);
  };

  const getSegmentValues = () => {
    const segmentValues = segments[currentName];
    if (map) {
      //for each field in each relevant mapping, define the field for the current form
      relevantMappings.forEach((mapping) => {
        const fromFormValues = segments[mapping.fromForm];
        mapping.fields.forEach((field) => {
          segmentValues[field] = fromFormValues[field];
        });
      });
    }
    return segmentValues;
  };

  const nextStep = () =>
    setCurrentStep(
      currentStep < formList.length - 1 ? currentStep + 1 : formList.length - 1
    );
  const prevStep = () => setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);

  return (
    <div className="o-scat5">
      <FormContext.Provider
        value={{
          values: getSegmentValues(),
          setValue: setValue,
          setValues: setValues,
          athlete: athlete,
          trainer: trainer,
        }}
      >
        <div className="o-scat5__formContainer">{formList[currentStep]}</div>
        <div className="o-scat5__pageIndicator">
          Page {currentStep + 1} of {formList.length}
        </div>
        <div className="o-scat5__buttonContainer">
          <CommonButton onClick={prevStep}>Previous</CommonButton>
          <CommonButton onClick={nextStep}>Next</CommonButton>
          <CommonButton modifiers={["red"]} onClick={() => onSubmit(segments)}>
            Submit
          </CommonButton>
        </div>
      </FormContext.Provider>
    </div>
  );
};

export default SCAT5;

import React from "react";
import { connect } from "react-redux";
import { logout } from "../../account/account-actions";
import { history } from "/resources/js/routing.js";

import DownArrow from "/resources/svg/down-arrow--white.svg";
import CommonButton from "../button/common-button.tsx";
import "./nav-menu.scss";

class NavMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  logout() {
    // console.log("logout");
    this.props.logout();
    history.push("/login");
  }

  render() {
    let menuClassModifier = "closed";
    if (this.state.open) {
      menuClassModifier = "open";
    }

    return (
      <div className="nav-menu">
        <CommonButton modifiers={["red"]} onClick={this.toggleMenu}>
          <div className="nav-menu__button__text">menu</div>
          <img className="nav-menu__button__img" src={DownArrow}></img>
        </CommonButton>
        <div
          className={`nav-menu__options nav-menu__options--${menuClassModifier}`}
        >
          <div onClick={this.logout}>Log Out</div>
        </div>
      </div>
    );
  }
}

export default connect(null, { logout })(NavMenu);

import fromEntries from "object.fromentries";
import "array-flat-polyfill";

import {
  // calculateElapsedCardioTime,
  calculateElapsedResistanceTime,
  identifyResultTypes,
  calculateJumps,
  calculateJumpsGood,
  calculateJumpPercentage,
  calculateSegmentTypeMaxHR,
  calculateSegmentTypeMinHR,
  calculateSegmentTypeAverageHR,
  calculateTotalCaloriesBurned,
  calculateExerciseCount,
  calculateElapsedFastFeetTime,
  calculateFastFeetScore,
  // calculateElapsedSegmentTypeTime,
  calculateElapsedSegmentDataTypeTime,
  calculateSegmentTypeJumps,
  calculateSegmentDataTypeJumps,
  calculateSegmentTypeTotalJumpPercentage,
  calculateSegmentDataTypeTotalJumpPercentage,
  calculateSegmentTypeHRData,
  calculateSegmentDataTypeHRData,
  calculateSegmentTypeRepData,
  exerciseDataTypes,
} from "@unbound/digijump-sessiondata-library";

export function getExerciseDataTypes() {
  return exerciseDataTypes;
}

export function getAthleteList(state) {
  return state.athleteReducer.entities;
}

export function getAthlete(state, id) {
  return state.athleteReducer.entities[id];
}

export function getAthletes(state) {
  return state.athleteReducer.entities;
}

export function getSlectedOrganization(state) {
  const id = state.accountReducer.selectedOrganization;
  return state.accountReducer.organizations[id];
}

export function getSelectedOrganizationAthletes(state) {
  return fromEntries(
    Object.entries(state.athleteReducer.entities).filter(([, athlete]) => {
      if (athlete.organizations) {
        const athleteOrgIds = athlete.organizations.map((org) => org.id);
        return athleteOrgIds.includes(
          state.accountReducer.selectedOrganization
        );
      }
    })
  );
}

export function getSelectedOrganizationMachines(state) {
  return fromEntries(
    Object.entries(state.machineReducer.entities).filter(([, machine]) => {
      const athleteOrgIds = machine.organizations.map((org) => org.id);
      return athleteOrgIds.includes(state.accountReducer.selectedOrganization);
    })
  );
}

export function getAthleteSegmentRecords(state, id) {
  const sessions = Object.values(state.sessionReducer.entities).filter(
    (session) => session.user_id == id
  );
  const segmentRecords = sessions
    .map((session) => {
      if (session.segment_records) {
        return session.segment_records.map((segmentRecord) => {
          return state.segmentRecordReducer.entities[segmentRecord];
        });
      }
    })
    .flat();
  const segmentRecordsFiltered = segmentRecords.filter(
    (record) => record !== undefined
  );
  if (segmentRecordsFiltered) {
    return segmentRecordsFiltered;
  }
  return [];
}

export function getSession(state, id) {
  return Object.values(state.sessionReducer.entities).find(
    (session) => session.id == id
  );
}

export function getSegmentRecord(state, id) {
  return Object.values(state.segmentRecordReducer.entities).find(
    (segmentRecord) => segmentRecord.id == id
  );
}

export function getSegmentRecordAthlete(state, id) {
  const segmentRecord = getSegmentRecord(state, id);
  if (segmentRecord) {
    const session = getSession(state, segmentRecord.session_id);
    if (session) {
      return getAthlete(state, session.user_id);
    }
  }
}

export function getCurrentSessionId(state, machineId) {
  const selectedMachine = state.machineReducer.entities[machineId];
  if (selectedMachine) {
    return selectedMachine.current_session_id;
  } else {
    return null;
  }
}

export function getSelectedSegmentRecords(state) {
  const selectedSegmentRecords = Object.values(
    state.segmentRecordReducer.entities
  ).filter((segmentRecord) => segmentRecord.selected);
  if (selectedSegmentRecords && selectedSegmentRecords[0]) {
    return selectedSegmentRecords;
  }
  return null;
}

export const getSegmentRecordResultData = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    const resultTypes = getResultTypes(state, segmentRecordId);
    const elapsedTargetedCardioTime = getElapsedSegmentDataTypeTime(
      state,
      segmentRecordId,
      exerciseDataTypes.TARGETED_CARDIO
    );
    const jumps = getSegmentDataTypeJumps(
      state,
      segmentRecordId,
      exerciseDataTypes.TARGETED_CARDIO
    );
    const jumpPercentage = getSegmentDataTypeTotalJumpPercentage(
      state,
      segmentRecordId,
      exerciseDataTypes.TARGETED_CARDIO
    );
    const cardioHRData = getSegmentDataTypeHRData(
      state,
      segmentRecordId,
      exerciseDataTypes.TARGETED_CARDIO
    );
    const resistanceHRData = getSegmentDataTypeHRData(
      state,
      segmentRecordId,
      exerciseDataTypes.RESISTANCE
    );
    const fastFeetHRData = getSegmentDataTypeHRData(
      state,
      segmentRecordId,
      exerciseDataTypes.SPEED_FOCUSED_CARDIO
    );
    const maxCardioHR = cardioHRData.maxHR;
    const maxResistanceHR = resistanceHRData.maxHR;
    const maxFastFeetHR = fastFeetHRData.maxHR;
    const minCardioHR = cardioHRData.minHR;
    const minResistanceHR = resistanceHRData.minHR;
    const minFastFeetHR = fastFeetHRData.minHR;
    const averageCardioHR = cardioHRData.avgHR;
    const averageResistanceHR = resistanceHRData.avgHR;
    const averageFastFeetHR = fastFeetHRData.avgHR;
    const calories = getCaloriesBurned(state, segmentRecordId);
    const elapsedResistanceTime = getElapsedSegmentDataTypeTime(
      state,
      segmentRecordId,
      exerciseDataTypes.RESISTANCE
    );
    const exercises = getExerciseCountByDataType(
      state,
      segmentRecordId,
      exerciseDataTypes.RESISTANCE
    );
    const pounds = state.segmentRecordReducer.entities[segmentRecordId].pounds;
    const totalReps =
      state.segmentRecordReducer.entities[segmentRecordId].totalReps;
    const elapsedFastFeetTime = getElapsedSegmentDataTypeTime(
      state,
      segmentRecordId,
      exerciseDataTypes.SPEED_FOCUSED_CARDIO
    );
    const fastFeetScore = getSegmentDataTypeJumps(
      state,
      segmentRecordId,
      exerciseDataTypes.SPEED_FOCUSED_CARDIO
    );
    const baselineResults = {
      warmUpCardioTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "BL-WARM-UP-CARDIO"
      ),
      warmUpCardioJumps: getSegmentTypeJumps(
        state,
        segmentRecordId,
        "BL-WARM-UP-CARDIO"
      ),
      warmUpCardioJumpPercentage: getSegmentTypeTotalJumpPercentage(
        state,
        segmentRecordId,
        "BL-WARM-UP-CARDIO"
      ),
      warmUpCardioHRData: getSegmentTypeHRData(
        state,
        segmentRecordId,
        "BL-WARM-UP-CARDIO"
      ),
      warmUpResistanceTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "BL-WARM-UP-RESISTANCE",
        "msecondsBaselineWarmUpResistancePaused"
      ),
      warmUpReps: getSegmentTypeRepData(
        state,
        segmentRecordId,
        "BL-WARM-UP-RESISTANCE"
      ),
      warmUpResistanceHRData: getSegmentTypeHRData(
        state,
        segmentRecordId,
        "BL-WARM-UP-RESISTANCE"
      ),
      fastFeetTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "FASTFEET"
      ),
      fastFeetScore: getSegmentTypeJumps(state, segmentRecordId, "FASTFEET"),
      fastFeetJumps: getSegmentTypeJumps(state, segmentRecordId, "FASTFEET"),
      fastFeetHRData: getSegmentTypeHRData(state, segmentRecordId, "FASTFEET"),
      torsoRotationTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "BL-TORSO-ROTATION"
      ),
      torsoRotationReps: getSegmentTypeRepData(
        state,
        segmentRecordId,
        "BL-TORSO-ROTATION"
      ),
      torsoRotationHRData: getSegmentTypeHRData(
        state,
        segmentRecordId,
        "BL-TORSO-ROTATION"
      ),
      singleJumpTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "BL-SINGLE-JUMP"
      ),
      singleJumpJumps: getSegmentTypeJumps(
        state,
        segmentRecordId,
        "BL-SINGLE-JUMP"
      ),
      singleJumpJumpPercentage: getSegmentTypeTotalJumpPercentage(
        state,
        segmentRecordId,
        "BL-SINGLE-JUMP"
      ),
      singleJumpHRData: getSegmentTypeHRData(
        state,
        segmentRecordId,
        "BL-SINGLE-JUMP"
      ),
      chestFlyTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "BL-CHEST-FLY"
      ),
      chestFlyReps: getSegmentTypeRepData(
        state,
        segmentRecordId,
        "BL-CHEST-FLY"
      ),
      chestFlyHRData: getSegmentTypeHRData(
        state,
        segmentRecordId,
        "BL-CHEST-FLY"
      ),
      warmDownTime: getElapsedSegmentTypeTime(
        state,
        segmentRecordId,
        "BL-WARM-DOWN"
      ),
      warmDownJumps: getSegmentTypeJumps(
        state,
        segmentRecordId,
        "BL-WARM-DOWN"
      ),
      warmDownJumpPercentage: getSegmentTypeTotalJumpPercentage(
        state,
        segmentRecordId,
        "BL-WARM-DOWN"
      ),
      warmDownHRData: getSegmentTypeHRData(
        state,
        segmentRecordId,
        "BL-WARM-DOWN"
      ),
    };
    return {
      resultTypes,
      elapsedTargetedCardioTime,
      jumps,
      jumpPercentage,
      maxCardioHR,
      maxResistanceHR,
      maxFastFeetHR,
      minCardioHR,
      minResistanceHR,
      minFastFeetHR,
      averageCardioHR,
      averageResistanceHR,
      averageFastFeetHR,
      calories,
      elapsedResistanceTime,
      exercises,
      pounds,
      totalReps,
      elapsedFastFeetTime,
      fastFeetScore,
      baselineResults,
    };
  }
  return {};
};

// export const getElapsedCardioTime = (state, segmentRecordId) => {
//   if (state.segmentRecordReducer.entities[segmentRecordId]) {
//     return calculateElapsedCardioTime(
//       state.segmentRecordReducer.entities[segmentRecordId]
//     );
//   }
// };

export const getElapsedResistanceTime = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateElapsedResistanceTime(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export const getJumps = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateJumps(state.segmentRecordReducer.entities[segmentRecordId]);
  }
};

export const getJumpsGood = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateJumpsGood(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export const getJumpPercentage = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateJumpPercentage(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export const getMaxHR = (state, segmentRecordId, segmentType) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeMaxHR(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};

export const getMinHR = (state, segmentRecordId, segmentType) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeMinHR(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};

export const getAverageHR = (state, segmentRecordId, segmentType) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeAverageHR(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};

export const getExerciseCount = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateExerciseCount(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export const getExerciseCountByDataType = (
  state,
  segmentRecordId,
  segDataType
) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    // return calculateExerciseCountByDataType(
    //   state.segmentRecordReducer.segmentRecord,
    //   segDataType
    // );
  }

  const segmentRecord = state.segmentRecordReducer.entities[segmentRecordId];
  if (!segmentRecord.events) {
    return null;
  }
  const endEvents = segmentRecord.events.filter((event) => {
    return event.eventType == "END_TIME" && event.segDataType == segDataType;
  });
  return endEvents.length;
};

export const getCaloriesBurned = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    const segmentRecord = state.segmentRecordReducer.entities[segmentRecordId];
    if (segmentRecord) {
      const session = state.sessionReducer.entities[segmentRecord.session_id];
      if (session) {
        const athlete = state.athleteReducer.entities[session.user_id];
        if (athlete) {
          return calculateTotalCaloriesBurned(
            state.segmentRecordReducer.entities[segmentRecordId],
            athlete
          );
        }
      }
    }
  }
};

export const getResultTypes = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return identifyResultTypes(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export function getUserId(state) {
  return state.accountReducer.currentUserId;
}

export const getElapsedFastFeetTime = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateElapsedFastFeetTime(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export const getFastFeetScore = (state, segmentRecordId) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateFastFeetScore(
      state.segmentRecordReducer.entities[segmentRecordId]
    );
  }
};

export const getElapsedSegmentTypeTime = (
  state,
  segmentRecordId,
  segmentType
) => {
  // if (state.segmentRecordReducer.entities[segmentRecordId]) {
  //   return calculateElapsedSegmentTypeTime(
  //     state.segmentRecordReducer.entities[segmentRecordId],
  //     segmentType,
  //   );
  // }

  //Separate out two arrays of all START and END events
  // const {majorVer, minorVer} = parseVersionNumbers(segmentRecord);
  const segmentRecord = state.segmentRecordReducer.entities[segmentRecordId];
  const segType = segmentType;
  if (!segmentRecord.events) {
    return null;
  }

  const startEvents = segmentRecord.events.filter((event) => {
    return event.eventType == "START_TIME" && event.segType == segType;
  });
  const endEvents = segmentRecord.events.filter((event) => {
    return event.eventType == "END_TIME" && event.segType == segType;
  });

  // console.log({ startEvents, endEvents }, segmentRecord);

  //If currently running then latest local END timestamp is set to Date.now()
  if (startEvents.length > endEvents.length) {
    endEvents.push({
      eventType: "END_TIME",
      timestamp: Date.now(),
      segType: segType,
    });
  }

  const totalElapsedTime = startEvents.reduce(
    (acc, sEvent, index) => {
      let msecondsSegmentPaused = 0;
      if (endEvents[index].msecondsSegmentPaused) {
        msecondsSegmentPaused = endEvents[index].msecondsSegmentPaused;
      }
      return (
        acc +
        (endEvents[index].timestamp - msecondsSegmentPaused) -
        sEvent.timestamp
      );
    },
    [0]
  );

  let elapsedTime = totalElapsedTime;
  let elapsedTimeSeconds = elapsedTime / 1000;

  return Math.floor(elapsedTimeSeconds);
};

export const getElapsedSegmentDataTypeTime = (
  state,
  segmentRecordId,
  segmentDataType
) => {
  // console.log("segment data type", segmentDataType);
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    // const segmentRecord = state.segmentRecordReducer.entities[segmentRecordId];
    // const segDataType = segmentDataType;
    // //Separate out two arrays of all START and END events
    // if (!segmentRecord.events) {
    //   return null;
    // }
    // const startEvents = segmentRecord.events.filter((event) => {
    //   return (
    //     event.eventType == "START_TIME" && event.segDataType == segDataType
    //   );
    // });
    // const endEvents = segmentRecord.events.filter((event) => {
    //   return event.eventType == "END_TIME" && event.segDataType == segDataType;
    // });
    // //If currently running then latest local END timestamp is set to Date.now()
    // if (startEvents.length > endEvents.length) {
    //   endEvents.push({
    //     eventType: "END_TIME",
    //     timestamp: Date.now(),
    //     segDataType: segDataType,
    //   });
    // }
    // const totalElapsedTime = startEvents.reduce(
    //   (acc, sEvent, index) => {
    //     let msecondsSegmentPaused = 0;
    //     if (endEvents[index].msecondsSegmentPaused) {
    //       msecondsSegmentPaused = endEvents[index].msecondsSegmentPaused;
    //     }
    //     return (
    //       acc +
    //       (endEvents[index].timestamp - msecondsSegmentPaused) -
    //       sEvent.timestamp
    //     );
    //   },
    //   [0]
    // );
    // console.log("total", totalElapsedTime);
    // let elapsedTime = totalElapsedTime;
    // let elapsedTimeSeconds = elapsedTime / 1000;
    // return Math.floor(elapsedTimeSeconds);
    return calculateElapsedSegmentDataTypeTime(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentDataType
    );
  }
};

export const getSegmentTypeJumps = (state, segmentRecordId, segmentType) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeJumps(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};

export const getSegmentDataTypeJumps = (
  state,
  segmentRecordId,
  segmentDataType
) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentDataTypeJumps(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentDataType
    );
  }
};

export const getSegmentTypeTotalJumpPercentage = (
  state,
  segmentRecordId,
  segmentType
) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeTotalJumpPercentage(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};

export const getSegmentDataTypeTotalJumpPercentage = (
  state,
  segmentRecordId,
  segmentDataType
) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentDataTypeTotalJumpPercentage(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentDataType
    );
  }
};

export const getSegmentTypeHRData = (state, segmentRecordId, segmentType) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeHRData(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};

export const getSegmentDataTypeHRData = (
  state,
  segmentRecordId,
  segmentDataType
) => {
  return calculateSegmentDataTypeHRData(
    state.segmentRecordReducer.entities[segmentRecordId],
    segmentDataType
  );
};

export const getSegmentTypeRepData = (state, segmentRecordId, segmentType) => {
  if (state.segmentRecordReducer.entities[segmentRecordId]) {
    return calculateSegmentTypeRepData(
      state.segmentRecordReducer.entities[segmentRecordId],
      segmentType
    );
  }
};
